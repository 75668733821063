<template>
    <div class="overlay">
        <div class="overlay__content">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Button',
    mounted() {
        document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    },
    unmounted() {
        document.getElementsByTagName('body')[0].style.overflow = 'initial'
    }
}
</script>

<style scoped>
body {
    overflow: hidden;
}
</style>

<style lang="scss">
@import '../styles/mixins';
@import '../styles/variables';

.overlay {
    background: rgba($black, .75);
    bottom: 0;
    left: 0;
    overflow-y: scroll;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9;

    &__content {
        @include transform(translateX(-50%));
        left: 50%;
        max-width: $global-width;
        padding: .5rem;
        position: absolute;
        top: 1rem;
        width: 100%;

        .panel {
            &__content {
                max-height: calc(100vh - 175px);
                overflow-y: scroll;
            }
        }
    }
}
</style>
