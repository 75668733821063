<template>
    <Header :actions="headerActions"
            :title="object ? 'product tag [' + object.name + ']' : 'product tag'"
            @action="headerAction"/>

    <div class="grid-x" v-if="$store.state.user && $store.state.user.active">
        <div class="cell auto">
<!--                <Tabs :current="currentTab"-->
<!--                      :tabs="tabs"-->
<!--                      @select="selectTab"/>-->

            <Panel :padding="false" header="associated products">
                <TableList :actions="productActions"
                           :data="products"
                           :loading="productsLoading"
                           ordering="title"
                           :selectable="true"
                           type="product"
                           @action="action"
                           @filter="getProducts"
                           @select="selectProducts"/>
            </Panel>

<!--                <Panel :padding="false" header="associated collections"-->
<!--                       v-if="currentTab === 'collections'">-->
<!--                    <TableList :actions="collectionActions"-->
<!--                               :data="collections"-->
<!--                               :loading="collectionsLoading"-->
<!--                               ordering="title"-->
<!--                               :selectable="true"-->
<!--                               type="collection"/>-->
<!--                </Panel>-->
        </div>
    </div>

    <UpgradePlan v-else/>

    <Overlay v-if="changeNameOverlay">
        <Panel :header="'updating ' + object.name" :close="true"
               :actions="changeNameActions" @close="toggleChangeNameOverlay" @action="panelAction">
            <Field label="tag name"
                   :value="object.name"
                   @submit="updateTag"
                   @value="setTagName"></Field>
        </Panel>
    </Overlay>

    <Overlay v-if="removeOverlay">
        <Panel :header="'confirm removing tag from ' + selectedProducts.length + ' products'"
               :close="true"
               :actions="removeActions" @close="toggleRemoveOverlay" @action="panelAction">
            <p>This will remove the [{{ object.name }}] tag from selected products.</p>
        </Panel>
    </Overlay>
</template>

<script>
import UpgradePlan from '@/components/UpgradePlan'
import Overlay from '@/components/Overlay'
import Header from '@/components/Header'
import Panel from '@/components/Panel'
import TableList from '@/components/TableList'
// import Tabs from '@/components/Tabs'
import Field from '@/components/Field'

export default {
    name: 'TagSingle',
    components: {
        UpgradePlan,
        Overlay,
        Header,
        Panel,
        TableList,
        // Tabs,
        Field
    },
    data() {
        return {
            removeOverlay: false,
            selectedProducts: [],
            removeActions: [
                {
                    type: 'warning',
                    value: 'removeProducts',
                    label: 'confirm'
                }
            ],
            newName: '',
            changeNameOverlay: false,
            changeNameActions: [
                {
                    type: 'primary',
                    value: 'changeName',
                    label: 'save'
                }
            ],
            headerActions: [
                {
                    label: 'change name',
                    icon: 'fas fa-pencil',
                    callback: 'changeName',
                    type: 'secondary',
                }
            ],
            productActions: [
                {
                    label: 'remove tag',
                    icon: 'fas fa-minus',
                    callback: 'removeProducts',
                    type: 'warning'
                }
            ],
            collectionActions: [
                {
                    label: 'change collection condition',
                    icon: 'fas fa-pencil',
                    callback: 'removeCollections'
                },
                {
                    label: 'remove tag from collections',
                    icon: 'fas fa-minus',
                    callback: 'removeCollections'
                }
            ],
            object: null,
            loading: true,
            currentTab: 'products',
            tabs: [
                {
                    label: 'products',
                    value: 'products'
                },
                {
                    label: 'collections',
                    value: 'collections'
                }
            ],
            products: null,
            productsLoading: true,
            collections: null,
            collectionsLoading: true
        }
    },
    methods: {
        action(action) {
            if (action === 'removeProducts') {
                this.toggleRemoveOverlay()
            }
        },
        toggleRemoveOverlay () {
            this.removeOverlay = !this.removeOverlay
        },
        panelAction(action) {
            if (action === 'changeName') {
                this.updateTagName()
            } else if (action === 'removeProducts') {
                this.removeProducts()
            }
        },
        headerAction(action) {
            if (action === 'changeName') {
                this.toggleChangeNameOverlay()
            }
        },
        setTagName(value) {
            this.newName = value
        },
        updateTag(value) {
            this.newName = value
            this.updateTagName()
        },
        updateTagName() {
            this.request('PUT',
                '/products/tags/' + this.$route.params.id + '/', {
                    name: this.newName
                }).then(response => {
                    if (response) {
                        this.$emit('notification', {
                            message: 'Product tag name change has started.',
                            type: 'success'
                        })
                        this.$emit('syncing')
                        this.object = response
                        this.loading = false
                        this.getObject()
                        this.toggleChangeNameOverlay()
                    } else {
                        this.$emit('notification', {
                            message: 'Something went wrong..',
                            type: 'error'
                        })
                    }
            })
        },
        toggleChangeNameOverlay() {
            this.changeNameOverlay = !this.changeNameOverlay
        },
        selectProducts (items) {
            this.selectedProducts = items
        },
        removeProducts() {
            this.request('PUT', '/products/tags/remove/', {
                tag_ids: [this.$route.params.id],
                product_ids: this.selectedProducts
            }).then(response => {
                if (response) {
                    this.$emit('notification', {
                        message: 'Removing products has started.',
                        type: 'success'
                    })
                    this.$emit('syncing')
                    this.confirming = false
                    this.removeOverlay = false
                    this.getProducts()
                } else {
                    this.$emit('notification', {
                        message: 'Something went wrong..',
                        type: 'error'
                    })
                }
            })
        },
        selectTab(value) {
            if (value === 'collections') {
                this.getCollections()
            } else {
                this.getProducts()
            }

            this.currentTab = value
        },
        getObject() {
            this.loading = true

            this.request('GET',
                '/products/tags/' + this.$route.params.id + '/').then(response => {
                this.object = response
                this.loading = false
                this.newName = response.name
            })
        },
        getProducts(data={}) {
            this.productsLoading = true

            this.request('GET',
                '/products/tags/' + this.$route.params.id + '/products/', data).then(
                    response => {
                if (response.data) {
                    this.products = response

                    this.productsLoading = false
                }
            })
        },
        getCollections(data={}) {
            this.collectionsLoading = true

            this.request('GET',
                '/products/tags/' + this.$route.params.id + '/collections/', data).then(
                response => {
                if (response.data) {
                    this.collections = response

                    this.collectionsLoading = false
                }
            })
        }
    },
    mounted() {
        this.getObject()
        this.getProducts()
    }
}
</script>

<style>

</style>