<template>
    <Panel header="plans">
        <Loader :loading="choosingPlan"></Loader>

        <div class="plans grid-x">
            <div class="cell auto" v-for="(plan, i) in plans" :key="i">
                <div class="plans__plan">
                    <h3>{{ plan.name }}</h3>

                    <div class="plans__plan__price">{{ plan.display_price }}</div>

                    <ul>
                        <li v-for="(feature, j) in plan.display_features" :key="j"
                            v-html="feature"></li>
                    </ul>

                    <div class="plans__plan__action">
                        <CustomButton :disabled="true"
                                      :fill="true"
                                      type="secondary"
                                      v-if="plan.active_subscription">
                            current</CustomButton>
                        <CustomButton :fill="true"
                                      type="primary"
                                      @click="selectSubscription(plan.id)"
                                      v-if="!plan.active_subscription && plan.availability.available">
                            select</CustomButton>
                        <CustomButton :disabled="true"
                                      :fill="true" 
                                      v-if="!plan.active_subscription && !plan.availability.available">
                            unavailable</CustomButton>
                    </div>

                    <p v-if="plan.active_subscription &&
                        plan.active_subscription.remaining_trial > 0">
                        <i>free trial remaining</i> <br>

                        <span class="text-accent-color text-900">
                            {{ plan.active_subscription.remaining_trial }} days
                        </span>
                    </p>

                    <p class="text-alert-color pointer"
                       v-if="plan.active_subscription && plan.active_subscription.price > 0">
                        <span @click="cancelPlan(plan.id)">cancel</span></p>

                    <p v-if="!plan.active_subscription && !plan.availability.available"
                       v-html="plan.availability.message"></p>
                </div>
            </div>
        </div>
    </Panel>
</template>

<script>
import Panel from '@/components/Panel'
import CustomButton from '@/components/CustomButton'
import Loader from '@/components/Loader'

export default {
    name: 'Plans',
    components: {
        Panel,
        CustomButton,
        Loader
    },
    data () {
        return {
            subscription: null,
            plans: [],
            choosingPlan: false
        }
    },
    methods: {
        selectSubscription (id) {
            this.choosingPlan = true

            this.request('POST', '/shops/plans/create/', {
                plan_id: id
            }).then(response => {
                if (response.redirect) {
                    window.top.location.href = response.redirect
                } else {
                    this.choosingPlan = false
                    this.getPlans()

                    this.request('get', '/shops/me/').then(response => {
                        this.$store.state.user = response
                    })
                }
            })
        },
        cancelPlan(id) {
            this.request('DELETE', '/shops/plans/' + id + '/delete/').then(response => {
                if (response)
                    this.getPlans()
            })
        },
        getPlans() {
            this.request('GET', '/shops/plans/').then(response => {
                this.plans = response.data
            })
        }
    },
    mounted () {
        this.getPlans()
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/mixins';
@import '../styles/variables';

.plans {
    &__plan {
        border-right: 1px solid $lighter-gray;
        padding: 0 2rem;
        text-align: center;

        ul {
            list-style: none;
            margin: 0 0 2rem;
            padding: 0;

            li {
                margin-bottom: 1rem;
            }
        }

        &__price {
            border-top: 1px solid $lighter-gray;
            border-bottom: 1px solid $lighter-gray;
            font-size: 1.5rem;
            padding: .5rem 0;
            margin-bottom: 2rem;
        }

        &__action {
            margin-bottom: .25em;
        }
    }

    .cell {
        &:last-child {
            .plans {
                &__plan {
                    border-right: 0;
                }
            }
        }
    }
}
</style>
