<template>
    <Panel>You must upgrade your plan to use this feature:
        <router-link :to="{ name: 'Settings' }">
            <CustomButton type="alert">upgrade plan</CustomButton>
        </router-link>
    </Panel>
</template>

<script>
import Panel from '@/components/Panel'
import CustomButton from '@/components/CustomButton'

export default {
    name: 'UpgradePlan',
    props: [
        'padding'
    ],
    components: {
        Panel,
        CustomButton
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/mixins';
@import '../styles/variables';

</style>
