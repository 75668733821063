<template>
    <router-link :to="{ name: 'TagSingle', params: { id: item.id } }">
        <div class="grid-x grid-padding-x align-middle" :class="classes">
            <div class="cell auto ellipsis" v-html="item.name"></div>
            <div class="cell auto col-medium text-accent-color"
                 v-html="item.product_count_display"></div>
<!--            <div class="cell auto col-medium text-accent-color"-->
<!--                 v-html="item.collection_count_display"></div>-->
            <div class="cell auto col-large" v-html="item.updated_at"></div>
        </div>
    </router-link>
</template>

<script>
import moment from 'moment'

export default {
    name: 'TagListItem',
    props: [
        'object',
        'classes'
    ],
    data() {
        return {
            item: this.object
        }
    },
    mounted() {
        this.item.product_count_display = this.item.product_count + ' products'

        if (this.item.product_count === 1) {
            this.item.product_count_display = this.item.product_count + ' product'
        }

        this.item.collection_count_display = this.item.collection_count + ' collections'

        if (this.item.collection_count === 1) {
            this.item.collection_count_display = this.item.collection_count + ' collection'
        }

        this.item.updated_at = moment(this.object.updated_at).format(
            'MMM D, YYYY [at] h:mma')
    }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/variables';
</style>