import { createWebHistory, createRouter } from 'vue-router'

// import Home from '@/pages/Home'
import Settings from '@/pages/settings/Settings'
import PrivacyPolicy from '@/pages/legal/PrivacyPolicy'
import TermsService from '@/pages/legal/TermsService'
import Tags from '@/pages/tags/List'
import TagSingle from '@/pages/tags/Single'
// import Products from '@/pages/products/List'
// import Collections from '@/pages/collections/List'
import Apps from '@/pages/apps/List'

const index = [
    {
        path: '/',
        name: 'Home',
        component: Tags,
    },
    {
        path: '/settings/',
        name: 'Settings',
        component: Settings,
    },
    {
        path: '/privacy-policy/',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
    },
    {
        path: '/terms-service/',
        name: 'TermsService',
        component: TermsService,
    },
    // {
    //     path: '/tags/',
    //     name: 'Tags',
    //     component: Tags,
    // },
    {
        path: '/tags/:id/',
        name: 'TagSingle',
        component: TagSingle
    },
    // {
    //     path: '/products/',
    //     name: 'Products',
    //     component: Products,
    // },
    // {
    //     path: '/collections/',
    //     name: 'Collections',
    //     component: Collections,
    // },
    {
        path: '/apps/',
        name: 'Apps',
        component: Apps,
    }
];

const producttagsRouter = createRouter({
    history: createWebHistory(),
    routes: index,
});

export default producttagsRouter;
