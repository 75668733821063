<template>
    <div class="grid-x grid-padding-x align-middle" :class="classes">
        <div class="cell auto">
            <div class="grid-x align-middle">
                <div class="cell shrink">
                    <div class="table-list__item__image">
                        <img :src="item.featured_image.thumb" :alt="item.title + ' Image'"
                             v-if="item.featured_image">
                        <div v-else><i class="fa-image fas"/></div>
                    </div>
                </div>

                <div class="cell auto ellipsis">
                    {{ item.title }}
                </div>
            </div>
        </div>
        <div class="cell auto col-small text-accent-color" v-html="item.tag_count_display"></div>
        <div class="cell auto col-small">
            <Label :label="item.status" :type="item.statusType"/>
        </div>
        <div class="cell auto col-medium" v-html="item.created_at"></div>
    </div>
</template>

<script>
import moment from 'moment'
import Label from '@/components/Label'

export default {
    name: 'ProductListItem',
    props: [
        'object',
        'classes'
    ],
    components: {
        Label
    },
    data() {
        return {
            item: this.object
        }
    },
    mounted() {
        this.item.statusType = 'success'

        if (this.item.status === 'draft') {
            this.item.statusType = 'warning'
        }

        if (this.item.status === 'archived') {
            this.item.statusType = 'alert'
        }

        this.item.tag_count_display = this.item.tag_count + ' tag'

        if (this.item.tag_count > 1) {
            this.item.tag_count_display = this.item.tag_count + ' tags'
        }

        this.item.created_at = moment(this.object.created_at).format(
            'MMM D, YYYY')
    }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/variables';
</style>