<template>
    <div class="cell auto sortable" @click="ordering('name')">
        product tag
        <span v-if="order === '-name'"><i class="fas fa-sort-up"></i></span>
        <span v-if="order === 'name'"><i class="fas fa-sort-down"></i></span>
        <span v-if="order.indexOf('name') < 0"><i class="fas fa-sort"></i></span>
    </div>
    <div class="cell auto col-medium sortable" @click="ordering('products__count')">
        products
        <span v-if="order === '-products__count'"><i class="fas fa-sort-up"></i></span>
        <span v-if="order === 'products__count'"><i class="fas fa-sort-down"></i></span>
        <span v-if="order.indexOf('products__count') < 0"><i class="fas fa-sort"></i></span>
    </div>
<!--    <div class="cell auto col-medium sortable" @click="ordering('collections__count')">-->
<!--        collections-->
<!--        <span v-if="order === '-collections__count'"><i class="fas fa-sort-up"></i></span>-->
<!--        <span v-if="order === 'collections__count'"><i class="fas fa-sort-down"></i></span>-->
<!--        <span v-if="order.indexOf('collections__count') < 0"><i class="fas fa-sort"></i></span>-->
<!--    </div>-->
    <div class="cell auto col-large sortable" @click="ordering('updated_at')">
        last updated
        <span v-if="order === '-updated_at'"><i class="fas fa-sort-up"></i></span>
        <span v-if="order === 'updated_at'"><i class="fas fa-sort-down"></i></span>
        <span v-if="order.indexOf('updated_at') < 0"><i class="fas fa-sort"></i></span>
    </div>
</template>

<script>
export default {
    name: 'TagListHeader',
    props: [
        'order',
        'actions',
        'args'
    ],
    emits: [
        'sort',
        'action'
    ],
    methods: {
        ordering(value) {
            this.$emit('sort', value)
        },

    }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/variables';
</style>