import $ from 'jquery/dist/jquery'
import 'foundation-sites/dist/js/plugins/foundation.core'
import 'foundation-sites/dist/js/plugins/foundation.util.mediaQuery'
import 'foundation-sites/dist/js/plugins/foundation.util.nest'
import 'foundation-sites/dist/js/plugins/foundation.util.keyboard'
import 'foundation-sites/dist/js/plugins/foundation.util.triggers'
import 'foundation-sites/dist/js/plugins/foundation.dropdownMenu'
import 'foundation-sites/dist/js/plugins/foundation.accordionMenu'
import 'foundation-sites/dist/js/plugins/foundation.responsiveMenu'
import 'foundation-sites/dist/js/plugins/foundation.responsiveToggle'
import 'foundation-sites/dist/js/plugins/foundation.sticky'
import 'foundation-sites/dist/js/plugins/foundation.tooltip'

$(document).foundation()
