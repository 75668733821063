import axios from 'axios'
import env from '@/mixins/env'

export default {
    methods: {
        request(method, url, data = null) {
            return new Promise((resolve, reject) => {
                this.getToken().then(token => {
                    method = method.toUpperCase()

                    const request = {
                        method: method,
                        url: env.api_url() + url + '?' + this.$store.state.params,
                    }

                    const dataCopy = data ? data : {}

                    if (this.$route.query.page && method === 'GET') {
                        dataCopy.page = this.$route.query.page
                    }

                    if (this.$route.query.search && method === 'GET') {
                        dataCopy.search = this.$route.query.search
                    }

                    if (this.$route.query.order && method === 'GET') {
                        dataCopy.ordering = this.$route.query.order
                    }

                    const headers = {
                        'Content-Type': 'application/json',
                    }

                    if (token) {
                        headers['Authorization'] = 'Bearer ' + token
                    }

                    request['headers'] = headers

                    if (method === 'GET') {
                        request['params'] = dataCopy
                    } else if (method === 'POST' || method === 'DELETE' || method === 'PUT') {
                        request['data'] = dataCopy
                    }

                    axios(request).then((response) => {
                        if (response.data.results !== undefined) {
                            const pageSize = dataCopy && dataCopy.page_size ? dataCopy.page_size : 50
                            const data = {
                                page: this.getPagination(response.data, pageSize),
                                data: response.data.results
                            }

                            resolve(data)
                        } else {
                            resolve(response.data)
                        }
                    }).catch(error => {
                        reject(error.response)
                    })
                })
            })
        },
        getToken() {
            return new Promise((resolve) => {
                if (this.$store) {
                    if (this.$store.state.token) {
                        const token = this.$store.state.token
                        const currentTime = new Date()
                        const expireTime = new Date(token.expire)

                        if (currentTime < expireTime) {
                            resolve(token.token)
                        } else {
                            axios({
                                method: 'POST',
                                url: env.api_url() + '/token/refresh/',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                data: {
                                    refresh: token.refresh
                                }
                            }).then((response) => {
                                const data = response.data
                                const expireTime = new Date()
                                expireTime.setMinutes(expireTime.getMinutes() + 5)

                                this.$store.state.token = {
                                    'token': data.access,
                                    'refresh': data.refresh,
                                    'expire': expireTime
                                }

                                resolve(data.access)
                            })
                        }
                    }
                }
            })
        },
        getPagination(data, pageSize) {
            const page = {
                total: data.count,
                count: data.results.length,
                current: 1,
                next: data.next,
                prev: data.previous,
                start: 1,
                end: data.length
            }

            if (page.count !== 0) {
                page.pages = Math.ceil(page.count / pageSize)
            }

            if (page.prev) {
                page.current = page.prev + 1
            }

            if (page.count === 0) {
                page.start = 0
            } else {
                page.start = pageSize * page.current + 1 - pageSize
            }

            page.end = pageSize * page.current + page.count - pageSize

            return page
        }
    }
}
