<template>
    <div class="app-menu">
        <router-link :to="{ name: 'Home' }">
            <div class="logo">
                <img src="@/assets/logo.svg" alt="Blissful apps"/>
                <br/><span>zipcode verifier</span>
            </div>
        </router-link>

        <ul>
            <li :class="{ 'active': this.$route.name.indexOf('Home') !== -1 }">
                <router-link :to="{ name: 'Home' }">
                    <span><i class="fa-home fas"/></span> home
                </router-link>
            </li>

<!--            <li :class="{ 'active': this.$route.name.indexOf('Customize') !== -1 }">-->
<!--                <router-link :to="{ name: 'Customize' }">-->
<!--                    <span><i class="fa-paint-brush fas"/></span> customize-->
<!--                </router-link>-->
<!--            </li>-->
        </ul>

<!--        <hr/>-->

        <ul>
            <li :class="{ 'active': this.$route.name.indexOf('Settings') !== -1 }">
                <router-link :to="{ name: 'Settings' }">
                    <span><i class="fa-cog fas"/></span> settings
                </router-link>
            </li>

            <li :class="{ 'active': this.$route.name.indexOf('Apps') !== -1 }">
                <router-link :to="{ name: 'Apps' }">
                    <span><i class="fa-th-large fas"/></span> apps
                </router-link>
            </li>
        </ul>

<!--        <hr/>-->

        <ul class="legal">
            <li :class="{ 'active': this.$route.name.indexOf('PrivacyPolicy') !== -1 }">
                <router-link :to="{ name: 'PrivacyPolicy' }">privacy policy</router-link>
            </li>
            <li :class="{ 'active': this.$route.name.indexOf('TermsService') !== -1 }">
                <router-link :to="{ name: 'TermsService' }">terms of service</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'ZipcodeVerifierMenu',
    data () {
        return {

        }
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>

</style>