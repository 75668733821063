<template>
    <div class="checkbox-area" :class="{ 'active': isChecked, 'fill': fill }" @click="check">
        <div class="checkbox"></div>
    </div>
</template>

<script>
export default {
    name: 'Button',
    props: [
        'checked',
        'fill'
    ],
    data() {
        return {
            isChecked: this.checked
        }
    },
    mounted() {

    },
    watch: {
        'checked': function () {
            this.isChecked = this.checked
        }
    },
    methods: {
        check() {
            this.isChecked = !this.isChecked
            this.$emit('check', this.isChecked)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/mixins';
@import '../styles/variables';

.checkbox-area {
    cursor: pointer;

    .checkbox {
        border: 1px solid $darker3-gray;
        border-radius: .25em;
        display: inline-block;
        height: 1rem;
        line-height: 1;
        width: 1rem;
    }

    &.fill {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;

        .checkbox {
            @include transform(translate(-50%, -50%));
            left: 50%;
            position: absolute;
            top: 50%;
        }
    }

    &:hover {
        .checkbox {
            background: $light-gray;
        }
    }

    &.active {
        .checkbox {
            background: $secondary-color;
        }

        &:hover {
            .checkbox {
                background: $secondary-lighter-color;
            }
        }
    }
}

.dark-mode {
    .checkbox-area {
        .checkbox {
            border-color: $medium-gray;
        }

        &.active {
            .checkbox {

            }
        }
    }
}
</style>
