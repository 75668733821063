<template>
    <div class="cell auto"><div class="skeleton skeleton--1"></div></div>
<!--    <div class="cell auto col-medium"><div class="skeleton skeleton&#45;&#45;2"></div></div>-->
    <div class="cell auto col-medium"><div class="skeleton skeleton--3"></div></div>
    <div class="cell auto col-large"><div class="skeleton skeleton--4"></div></div>
</template>

<script>
export default {
    name: 'TagListItemSkeleton'
}
</script>

<style lang="scss" scoped>
@import '../../../styles/variables';

.skeleton {
    &--1 {
        max-width: 15rem;
    }

    &--2 {
        max-width: 8rem;
    }

    &--3 {
        max-width: 8rem;
    }

    &--4 {
        max-width: 10rem;
    }
}
</style>