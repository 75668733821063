<template>
    <div class="cell auto sortable" @click="ordering('title')">
        collection
        <span v-if="order === '-title'"><i class="fas fa-sort-up"></i></span>
        <span v-if="order === 'title'"><i class="fas fa-sort-down"></i></span>
        <span v-if="order.indexOf('title') < 0"><i class="fas fa-sort"></i></span>
    </div>
    <div class="cell auto col-small">tags</div>
    <div class="cell auto col-large">published</div>
</template>

<script>
export default {
    name: 'CollectionListHeader',
    props: [
        'order',
        'actions',
        'args'
    ],
    emits: [
        'sort',
        'action'
    ],
    methods: {
        ordering(value) {
            this.$emit('sort', value)
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/variables';
</style>