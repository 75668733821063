<template>
    <Overlay>
        <div class="grid-container grid-container--small">
            <Panel header="welcome to blissful" :actions="actions" @action="panelAction">
                <div style="min-height: 15rem;">
                    <div v-if="step === 1">
                        <h5>hello {{ $store.state.user.name }},</h5>

                        <p>With this app you will be able to specify what zipcodes your store
                            delivers to and customers will be presented with a popup upon visiting
                            your site. This popup will ask them to enter their zipcode and it will
                            simply check against zipcodes you have entered to tell them if you
                            deliver in their area.</p>

                        <p>Note that by default there are no zipcodes stored so the popup will verify
                            any zipcode entered successfully and the popup is disabled. Ensure to
                            enable it.</p>

                        <p>Please accept the terms before using the app.</p>

                        <div class="grid-x" style="margin-top: 4rem;">
                            <div class="cell shrink">
                                <div style="margin-right: 1rem;">
                                    <Checkbox :checked="agreedToTerms" @check="agreeToTerms"/>
                                </div>
                            </div>

                            <div class="cell auto" style="position: relative; top: -2px;">
                                <label class="checkbox-choice__label">I agree to the blissful apps
                                    <router-link :to="{ name: 'TermsService' }">
                                        <span class="text-secondary-color">terms of service</span>
                                    </router-link> and
                                    <router-link :to="{ name: 'PrivacyPolicy' }">
                                        <span class="text-secondary-color">privacy policy</span>
                                    </router-link>.
                                </label>
                            </div>
                        </div>
                    </div>

<!--                    <div v-if="step === 2">-->
<!--                        <h5>key features</h5>-->

<!--                        <ul>-->
<!--                            <li>View products associated to a  product tag.</li>-->
<!--                            <li>Bulk add product tags to products.</li>-->
<!--                            <li>Bulk remove product tags from products.</li>-->
<!--                            <li>Bulk delete product tags.</li>-->
<!--                        </ul>-->

<!--                        <p>Our mission is to make managing your tags quicker and more transparent of-->
<!--                            what tags you have and what they are assigned to.</p>-->
<!--                    </div>-->
                </div>
            </Panel>
        </div>
    </Overlay>
</template>

<script>
import Overlay from '@/components/Overlay'
import Panel from '@/components/Panel'
import Checkbox from '@/components/Checkbox'

export default {
    name: 'Welcome',
    components: {
        Overlay,
        Panel,
        Checkbox
    },
    data () {
        return {
            show_tutorial: true,
            agreedToTerms: false,
            step: 1,
            actions: [
                {
                    label: 'continue',
                    type: 'primary',
                    value: 2,
                    disabled: true
                }
            ]
        }
    },
    methods: {
        panelAction(action) {
            this.step = action
            this.actions[0].value = this.step + 1

            if (this.step === 2) {
                this.show_tutorial = false

                this.request('PUT', '/shops/app-settings/', {
                    tutorial: true,
                }).then(response => {
                    if (response) {
                        this.$store.state.user.app_settings = response
                    }
                })
            }
        },
        agreeToTerms() {
            this.agreedToTerms = !this.agreedToTerms
            this.actions[0].disabled = !this.agreedToTerms
        }
    }
}
</script>

<style lang="scss" scoped>
</style>