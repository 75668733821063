<template>
    <div class="page-header">
        <div class="grid-x">
            <div class="cell shrink">
                <h1 v-html="title"></h1>
            </div>

            <div class="cell auto text-right"
                 v-if="actions && $store.state.user && $store.state.user.active">
                <div class="grid-x align-right">
                    <div class="cell shrink" v-for="(action, i) in actions" :key="i">
                        <CustomButton
                            :class="{ 'rotate': action.rotate }"
                            :icon="action.icon"
                            :type="action.type"
                            @click="$emit('action', action.callback)">
                            {{ action.label }}
                        </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="page-header-container"></div>
</template>

<script>
import CustomButton from '@/components/CustomButton';

export default {
    name: 'Header',
    components: {
        CustomButton
    },
    props: [
        'title',
        'loading',
        'actions'
    ]
}
</script>

<style lang="scss" scoped>
@import '../styles/variables';

.page-header {
    background: $lighter-gray;
    //background: $light-medium-gray;
    //border: 1px solid $medium-gray;
    border-top: 0;
    border-left: 0;
    height: 4.25rem;
    left: 12rem;
    padding: 1.5rem 2rem .75rem;
    position: fixed;
    right: -1rem;
    top: 0;
    z-index: 9;

    h1 {
        margin: 0;
    }
}

.page-header-container {
    height: 4.25rem;
    margin-bottom: 1rem;
}

.dark-mode {
    .page-header {
        background: $darkest-gray;
        border-color: $darker2-gray;
        color: $white;
    }
}
</style>