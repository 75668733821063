<template>
    <Header title="blissful apps"/>

    <div class="grid-x grid-padding-x grid-padding-y medium-up-2 large-up-3">
        <div class="cell" v-for="(object, i) in data.data" :key="i">
            <div class="app-card">
                <h3 class="app-card__heading" v-html="object.name"></h3>

                <div class="app-card__status" v-if="object.is_installed">
                    <i class="fa-check fas"></i> installed
                </div>

                <div class="app-card__actions grid-x grid-padding-x align-center">
                    <div class="cell small-6">
                        <a :href="'https://apps.shopify.com/' + object.slug" target="_blank">
                            <CustomButton fill="true" size="small">view details</CustomButton>
                        </a>
                    </div>

                    <div class="cell small-6" v-if="!object.is_installed">
                        <a :href="'https://' + object.domain + '/?shop=' + $store.state.user.myshopify_domain"
                           target="_blank">
                            <CustomButton fill="true" size="small" type="primary">
                                install</CustomButton>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/Header'
import CustomButton from '@/components/CustomButton'

export default {
    name: 'Apps',
    components: {
        CustomButton,
        Header
    },
    data() {
        return {
            data: []
        }
    },
    methods: {
        getObjects() {
            this.loading = true

            this.request('GET', '/shops/apps/').then(response => {
                if (response.data) {
                    this.data = response

                    this.loading = false
                }
            })
        }
    },
    mounted() {
        this.getObjects()
    }
}
</script>

<style lang="scss">
@import '../../styles/variables';
@import '../../styles/mixins';

.app-card {
    @include shadow(0 5px 10px rgba($black, .25));
    background: #ddd;
    border-radius: 2rem;
    padding: 1rem 1rem 40%;
    position: relative;

    &__heading {
        font-size: 1.5rem;
        margin-bottom: .25em;
    }

    &__status {
        color: $success-color;
        font-size: .75rem;
    }

    &__actions {
        bottom: 1rem;
        left: 1rem;
        position: absolute;
        right: 1rem;
    }
}
</style>