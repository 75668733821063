<template>
    <div class="grid-x grid-padding-x align-middle">
        <div class="cell auto">
            <Search @filter="search"/>
        </div>
    </div>
</template>

<script>
import Search from '@/components/Search'

export default {
    name: 'TagListFilter',
    components: {
        Search
    },
    emits: ['filter'],
    methods: {
        search(data) {
            this.$emit('filter', data)
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/variables';
</style>