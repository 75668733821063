<template>
    <Header title="product tags" :actions="headerActions" @action="action"/>

    <Panel :padding="false" v-if="$store.state.user && $store.state.user.active">
        <TableList :actions="actions"
                   :data="data"
                   :loading="loading"
                   ordering="-updated_at"
                   :selectable="true"
                   type="tag"
                   @action="action"
                   @filter="getObjects"
                   @select="selectTags"/>
    </Panel>

    <UpgradePlan v-else-if="$store.state.user && !$store.state.user.active"/>

    <Overlay v-if="newTagOverlay">
        <Panel header="create new tag" :close="true"
               :actions="newTagActions" @close="toggleNewTagOverlay" @action="panelAction"
               v-if="!confirming">
            <Loader :loading="savingNewTag"></Loader>
            <p>New tags will not show up in the Shopify admin until you associate a product or
                collection to it.</p>
            <Field label="tag name"
                   placeholder="name..."
                   @submit="createTag"
                   @value="setTagName"></Field>
        </Panel>
    </Overlay>

    <Overlay v-if="productsOverlay">
        <Panel :padding="false" header="select products to add to tags" :close="true"
               :actions="productSelectActions" @close="toggleProductsOverlay" @action="panelAction"
               v-if="!confirming">
            <TableList :data="products"
                       :loading="productsLoading"
                       ordering="title"
                       :selectable="true"
                       type="product"
                       @filter="getProducts"
                       @select="selectProducts"/>
        </Panel>

        <Panel header="confirm" :close="true" :actions="confirmActions"
               @close="toggleProductsOverlay" @action="panelAction" v-if="confirming">
            <div>Adding {{ selectedTags.length }} tags to {{ selectedProducts.length }}
                products.</div>
        </Panel>
    </Overlay>

    <Overlay v-if="productsRemoveOverlay">
        <Panel :padding="false" header="select products to remove from tags" :close="true"
               :actions="productRemoveActions" @close="toggleRemoveOverlay"
               @action="panelAction"
               v-if="!confirming">
            <TableList :data="products"
                       :loading="productsLoading"
                       ordering="title"
                       :selectable="true"
                       type="product"
                       @filter="getProducts"
                       @select="selectProducts"/>
        </Panel>

        <Panel header="confirm" :close="true" :actions="removeActions"
               @close="toggleRemoveOverlay" @action="panelAction" v-if="confirming">
            <div>Removing {{ selectedTags.length }} tags from {{ selectedProducts.length }}
                products.</div>
        </Panel>
    </Overlay>

    <Overlay v-if="deleteOverlay">
        <Panel :header="'confirm deleting ' + selectedTags.length + ' tags'" :close="true"
               :actions="deleteActions" @close="toggleDeleteOverlay" @action="panelAction">
            <p>This will remove tags from all products for the selected tags and delete the tag
                from our system.</p>
            <p><i>This operation cannot be undone.</i></p>
        </Panel>
    </Overlay>
</template>

<script>
import UpgradePlan from '@/components/UpgradePlan'
import Overlay from '@/components/Overlay'
import Header from '@/components/Header'
import Panel from '@/components/Panel'
import TableList from '@/components/TableList'
import Field from '@/components/Field'

export default {
    name: 'Tags',
    components: {
        UpgradePlan,
        Overlay,
        Header,
        Panel,
        TableList,
        Field
    },
    props: ['syncing'],
    data() {
        return {
            headerActions: [
                {
                    label: 'create new tag',
                    icon: 'fas fa-plus',
                    callback: 'addNewTag',
                    type: 'secondary',
                }
            ],
            actions: [
                {
                    label: 'add to products',
                    icon: 'fas fa-plus',
                    type: 'primary',
                    callback: 'addProducts'
                },
                {
                    label: 'remove from products',
                    icon: 'fas fa-minus',
                    type: 'warning',
                    callback: 'removeProducts'
                },
                {
                    label: 'delete tags',
                    icon: 'fas fa-trash-alt',
                    type: 'alert',
                    callback: 'deleteTags'
                }
            ],
            savingNewTag: false,
            newTagName: null,
            data: null,
            loading: true,
            selectedTags: [],
            products: null,
            productsLoading: true,
            productsOverlay: false,
            selectedProducts: [],
            confirming: false,
            deleteOverlay: false,
            newTagOverlay: false,
            newTagActions: [
                {
                    type: 'primary',
                    value: 'create-tag',
                    label: 'Create'
                }
            ],
            productsRemoveOverlay: false,
            productSelectActions: [
                {
                    type: 'primary',
                    value: 'add',
                    label: 'add products'
                }
            ],
            confirmActions: [
                {
                    type: 'primary',
                    value: 'confirm',
                    label: 'confirm'
                }
            ],
            productRemoveActions: [
                {
                    type: 'primary',
                    value: 'remove',
                    label: 'remove products'
                }
            ],
            removeActions: [
                {
                    type: 'warning',
                    value: 'remove-conform',
                    label: 'confirm'
                }
            ],
            deleteActions: [
                {
                    type: 'alert',
                    value: 'delete',
                    label: 'confirm'
                }
            ]
        }
    },
    methods: {
        setTagName(value) {
            this.newTagName = value
        },
        selectTags(items) {
            this.selectedTags = items
        },
        selectProducts(items) {
            this.selectedProducts = items
        },
        panelAction(action) {
            if (action === 'add' || action === 'remove') {
                this.confirming = true
            } else if (action === 'confirm') {
                if (this.syncing) {
                    this.$emit('notification', {
                        message: 'Cannot start a new task while one is already running.',
                        type: 'error'
                    })

                    this.productsOverlay = false
                    this.confirming = false

                    return
                }

                this.request('PUT', '/products/tags/add/', {
                    tag_ids: this.selectedTags,
                    product_ids: this.selectedProducts
                }).then(response => {
                    if (response) {
                        this.$emit('notification', {
                            message: 'Adding product tags has started.',
                            type: 'success'
                        })
                        this.productsOverlay = false
                        this.confirming = false
                        this.$emit('syncing')
                        this.getObjects()
                    } else {
                        this.$emit('notification', {
                            message: 'Something went wrong.',
                            type: 'error'
                        })
                    }
                })
            } else if (action === 'remove-conform') {
                this.removeTags()
            } else if (action === 'delete') {
                this.deleteTags()
            } else if (action === 'create-tag') {
                this.createTag()
            }
        },
        action(action, items) {
            if (action === 'addNewTag')
                this.toggleNewTagOverlay()

            if (action === 'addProducts')
                this.toggleProductsOverlay(items)

            if (action === 'removeProducts')
                this.toggleRemoveOverlay()

            if (action === 'deleteTags')
                this.toggleDeleteOverlay()
        },
        toggleNewTagOverlay() {
            this.newTagOverlay = !this.newTagOverlay
        },
        toggleDeleteOverlay() {
            this.deleteOverlay = !this.deleteOverlay
        },
        toggleProductsOverlay() {
            this.productsOverlay = !this.productsOverlay
            this.getProducts()
        },
        toggleRemoveOverlay() {
            this.productsRemoveOverlay = !this.productsRemoveOverlay
            this.getTagsProducts()
        },
        createTag(name = null) {
            this.savingNewTag = true
            this.newTagName = name ? name : this.newTagName

            this.request('POST', '/products/tags/create/', {
                name: this.newTagName
            }).then(response => {
                if (response) {
                    this.$emit('notification', {
                        message: 'Product tag has been created.',
                        type: 'success'
                    })
                    this.newTagOverlay = false
                    this.getObjects()
                } else {
                    this.$emit('notification', {
                        message: 'Something went wrong.',
                        type: 'error'
                    })
                }

                this.savingNewTag = false
            })
        },
        removeTags() {
            if (this.syncing) {
                this.$emit('notification', {
                    message: 'Cannot start a new task while one is already running.',
                    type: 'error'
                })
                this.confirming = false
                this.productsRemoveOverlay = false

                return
            }

            this.request('PUT', '/products/tags/remove/', {
                tag_ids: this.selectedTags,
                product_ids: this.selectedProducts
            }).then(response => {
                if (response) {
                    this.$emit('notification', {
                        message: 'Removing product tags has been started.',
                        type: 'success'
                    })
                    this.confirming = false
                    this.productsRemoveOverlay = false
                    this.$emit('syncing')
                    this.getObjects()
                } else {
                    this.$emit('notification', {
                        message: 'Something went wrong.',
                        type: 'error'
                    })
                }
            })
        },
        deleteTags() {
            if (this.syncing) {
                this.$emit('notification', {
                    message: 'Cannot start a new task while one is already running.',
                    type: 'error'
                })
                this.deleteOverlay = false

                return
            }

            this.request('DELETE', '/products/tags/delete/', {
                tag_ids: this.selectedTags
            }).then(response => {
                if (response) {
                    this.$emit('notification', {
                        message: 'Deleting product tags has started.',
                        type: 'success'
                    })
                    this.deleteOverlay = false
                    this.$emit('syncing')
                    this.getObjects()
                } else {
                    this.$emit('notification', {
                        message: 'Something went wrong.',
                        type: 'error'
                    })
                }
            })
        },
        getTagsProducts(data={}) {
            this.productsLoading = true
            data.tag_ids = this.selectedTags

            this.request('GET', '/products/', data).then(response => {
                if (response.data) {
                    this.products = response

                    this.productsLoading = false
                }
            })
        },
        getProducts(data={}) {
            this.productsLoading = true

            this.request('GET', '/products/', data).then(response => {
                if (response.data) {
                    this.products = response

                    this.productsLoading = false
                }
            })
        },
        getObjects(data={}) {
            this.loading = true

            this.request('GET', '/products/tags/', data).then(response => {
                if (response.data) {
                    this.data = response

                    this.loading = false
                }
            })
        }
    },
    mounted() {
        this.getObjects()
    }
}
</script>

<style>

</style>