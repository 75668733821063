<template>
    <div v-if="($route.name !== 'TermsService' && $route.name !== 'PrivacyPolicy') && ($store.state.user && !$store.state.user.app_settings.tutorial) && $store.state.token">
        <ProductTagsWelcome v-if="currentApp === 'producttags.blissfulapps.com'"/>
        <ZipcodeVerifierWelcome v-if="currentApp === 'zipcodeverifier.blissfulapps.com'"/>
    </div>
</template>

<script>
import { APP } from '@/local'
import ProductTagsWelcome from '@/apps/producttags/Welcome'
import ZipcodeVerifierWelcome from '@/apps/zipcodeverifier/Welcome'

export default {
    name: 'AbstractWelcome',
    components: {
        ProductTagsWelcome,
        ZipcodeVerifierWelcome
    },
    data () {
        return {
            currentApp: APP
        }
    }
}
</script>

<style lang="scss" scoped>
</style>