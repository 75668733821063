import axios from 'axios'
import env from '@/mixins/env'

const authentication = {
    methods: {
        authenticate() {
            let params = env.params()
            axios({
                method: 'GET',
                url: env.api_url() + '/authenticate/?' + params,
            }).then((response) => {
                const data = response.data

                if (data.access) {
                    const expireTime = new Date()
                    expireTime.setMinutes(expireTime.getMinutes() + 5)

                    this.$store.state.params = params

                    this.$store.state.token = {
                        'token': data.access,
                        'refresh': data.refresh,
                        'expire': expireTime
                    }

                    this.request('get', '/shops/me/').then(response => {
                        this.$store.state.user = response
                    })
                } else if (data.redirect) {
                    window.top.location.href = data.redirect
                }
            })
        }
    },
    mounted() {
        this.authenticate()
    }
}

export default authentication
