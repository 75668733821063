<template>
    <TagListFilter v-if="type === 'tag'" @filter="filter"/>
    <ProductListFilter v-if="type === 'product'" @filter="filter"/>
    <CollectionListFilter v-if="type === 'collection'" @filter="filter"/>
</template>

<script>
import TagListFilter from '@/pages/tags/components/ListFilter'
import ProductListFilter from '@/pages/products/components/ListFilter'
import CollectionListFilter from '@/pages/collections/components/ListFilter'

export default {
    name: 'AbstractListFilter',
    props: [
        'type'
    ],
    components: {
        TagListFilter,
        ProductListFilter,
        CollectionListFilter
    },
    emits: ['filter'],
    methods: {
        filter(data) {
            this.$emit('filter', data)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/variables';
</style>