<template>
    <div class="pagination grid-x align-middle" v-if="page">
        <div class="cell auto">
            <div>Showing {{ page.start }} to {{ page.end }} of {{ page.total }}</div>
        </div>

        <div class="cell shrink">
            <div class="pagination__arrow" :class="{ 'disabled': page.prev === null }"
                 @click="paginate(page.prev)">
                <i class="fa-angle-left far"></i>
            </div>

            <div class="pagination__arrow" :class="{ 'disabled': page.next === null }"
                 @click="paginate(page.next)">
                <i class="fa-angle-right far"></i>
            </div>
        </div>
    </div>
</template>

<script>
import utils from '@/mixins/utils'

export default {
    name: 'Pagination',
    props: [
        'page'
    ],
    mixins: [
        utils
    ],
    methods: {
        paginate(page) {
            if (page) {
                this.addParamsToLocation({
                    page: page
                })

                this.$emit('paginate', page)
            }
        }
    },
    mounted() {

    }
}
</script>

<style lang="scss" scoped>
@import '../styles/variables';
@import '../styles/mixins';

.pagination {
    font-size: 13px;
    font-style: italic;
    padding: 1rem;

    &__arrow {
        @include transition(background .2s);
        color: $dark3-gray;
        cursor: pointer;
        background: $light-medium-gray;
        border: 1px solid $medium-gray;
        border-radius: 2rem;
        display: inline-block;
        font-size: 1.25rem;
        line-height: 2rem;
        margin-right: .25rem;
        text-align: center;
        width: 2rem;

        svg {
            position: relative;
            top: .1rem;
        }

        .fa-angle-left {
            left: -2px;
        }

        .fa-angle-right {
            right: -2px;
        }

        &:hover {
            background: darken($light-medium-gray, 5%);
        }

        &:last-child {
            margin-right: 0;
        }

        &.disabled {
            cursor: initial;
            opacity: .5;

            &:hover {
                background: $light-medium-gray;
            }
        }
    }
}

.dark-mode {
    .pagination {
        color: $white;

        &__arrow {
            background: $dark2-gray;
            border-color: $dark-gray;
            color: $white;

            &:hover {
                background: darken($dark2-gray, 5%);
            }

            &.disabled {
                &:hover {
                    background: $dark2-gray;
                }
            }
        }
    }
}
</style>