import '@/scripts/_foundation'
import '@fortawesome/fontawesome-free/js/all.js';
import '@fortawesome/fontawesome-pro/js/all.js';

global.jQuery = require('jquery')
let $ = global.jQuery
window.$ = $

$(document).ready(function () {
  $(window).scroll(function () {
    $('.sticky').each(function () {
      let offset = $(this).offset().top

      if ($(this).attr('data-offset')) {
        offset = $(this).attr('data-offset')
      } else {
        $(this).attr('data-offset', offset)
      }

      if ($('.page-header').length > 0) {
        offset -= $('.page-header').outerHeight();
      }

      if ($(window).scrollTop() >= offset) {
        if (!$(this).hasClass('fixed')) {
          $(this).addClass('fixed')
        }
      } else {
        if ($(this).hasClass('fixed')) {
          $(this).removeClass('fixed')
        }
      }
    })
  })
})
