<template>
    <TagListHeader v-if="type === 'tag'" :order="currentOrder" :actions="actions" @sort="sort"
                   :args="args" @action="action"/>
    <ProductListHeader v-if="type === 'product'" :order="currentOrder" :actions="actions"
                       :args="args" @sort="sort" @action="action"/>
    <CollectionListHeader v-if="type === 'collection'" :order="currentOrder" :actions="actions"
                          :args="args" @sort="sort" @action="action"/>
</template>

<script>
import utils from '@/mixins/utils'
import TagListHeader from '@/pages/tags/components/ListHeader'
import ProductListHeader from '@/pages/products/components/ListHeader'
import CollectionListHeader from '@/pages/collections/components/ListHeader'

export default {
    name: 'AbstractListHeader',
    props: [
        'type',
        'ordering',
        'actions',
        'args'
    ],
    mixins: [
        utils
    ],
    components: {
        TagListHeader,
        ProductListHeader,
        CollectionListHeader
    },
    data() {
        return {
            currentOrder: ''
        }
    },
    emits: [
        'filter',
        'action'
    ],
    methods: {
        action(action) {
            this.$emit('action', action)
        },
        sort(value) {
            if (value) {
                if (this.currentOrder.indexOf(value) > -1 && this.currentOrder.indexOf('-') === -1) {
                    value = '-' + value
                } else if (this.currentOrder.indexOf(value) > -1) {
                    value = value.replace('-', '')
                }

                this.currentOrder = value
            }

            this.addParamsToLocation({
                order: value,
            })

            if (this.$route.query.page) {
                this.addParamsToLocation({
                    page: 1,
                })

                this.$route.query.page = 1
            }

            this.$emit('filter', {
                'ordering': value
            })
        }
    },
    mounted() {
        if (this.$route.query.order) {
            this.currentOrder = this.$route.query.order
        } else {
            this.currentOrder = this.ordering
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/variables';
</style>