<template>
    <div class="loading" v-if="loading"></div>
</template>

<script>
export default {
    name: 'Loader',
    props: ['loading']
}
</script>

<style lang="scss">
.loading {
    background: rgba(#ffffff, .8);
    border-radius: 2rem;
    bottom: 0;
    left: 0;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;

    &:before {
        background: url(../assets/loading.gif) center/auto 100% no-repeat;
        content: '';
        height: 60px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }
}

@keyframes placeholderShimmer {
    0% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0 50%;
    }
}

@keyframes gradient-animation {
    from {
        left: 0%;
    }
    to {
        left: 100%;
    }
}
</style>