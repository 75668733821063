<template>
    <div class="cell auto sortable" @click="ordering('title')">
        product
        <span v-if="order === '-title'"><i class="fas fa-sort-up"></i></span>
        <span v-if="order === 'title'"><i class="fas fa-sort-down"></i></span>
        <span v-if="order.indexOf('title') < 0"><i class="fas fa-sort"></i></span>
    </div>
    <div class="cell auto col-small">tags</div>
    <div class="cell auto col-small sortable" @click="ordering('status')">
        status
        <span v-if="order === '-status'"><i class="fas fa-sort-up"></i></span>
        <span v-if="order === 'status'"><i class="fas fa-sort-down"></i></span>
        <span v-if="order.indexOf('status') < 0"><i class="fas fa-sort"></i></span>
    </div>
    <div class="cell auto col-medium sortable" @click="ordering('-created_at')">
        created
        <span v-if="order === '-created_at'"><i class="fas fa-sort-up"></i></span>
        <span v-if="order === 'created_at'"><i class="fas fa-sort-down"></i></span>
        <span v-if="order.indexOf('created_at') < 0"><i class="fas fa-sort"></i></span>
    </div>

    <div class="actions cell shrink" v-if="actions">
        <div class="grid-x">
            <div class="actions__action cell shrink">
                <CustomButton icon="fas fa-plus" @click="$emit('action', 'addProducts')">add
                    {{ args.selectedCount }} selected products</CustomButton>
            </div>
        </div>
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton'

export default {
    name: 'ProductListHeader',
    components: {
        CustomButton
    },
    props: [
        'order',
        'actions',
        'args'
    ],
    emits: [
        'sort',
        'action'
    ],
    methods: {
        ordering(value) {
            this.$emit('sort', value)
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/variables';
</style>