<template>
    <TagItem :classes="classes" :object="object" v-if="type === 'tag'"/>
    <ProductItem :classes="classes" :object="object" v-if="type === 'product'"/>
    <CollectionItem :classes="classes" :object="object" v-if="type === 'collection'"/>
</template>

<script>
import TagItem from '@/pages/tags/components/ListItem'
import ProductItem from '@/pages/products/components/ListItem'
import CollectionItem from '@/pages/collections/components/ListItem'

export default {
    name: 'AbstractListItem',
    props: [
        'type',
        'object',
        'classes'
    ],
    components: {
        TagItem,
        ProductItem,
        CollectionItem
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/variables';
</style>