import { createApp } from 'vue'
import App from './App'
import producttagsRouter from '@/apps/producttags/router'
import zipcodeverifierRouter from '@/apps/zipcodeverifier/router'
import store from '@/store'
import client from '@/mixins/client'
import { APP as ShopifyApp } from '@/local'

import '@/styles/app.scss'
import '@/scripts/app'

const app = createApp(App)

window.$ = window.jQuery = require('jquery');

let router = null

if (ShopifyApp === 'producttags.blissfulapps.com') router = producttagsRouter
if (ShopifyApp === 'zipcodeverifier.blissfulapps.com') router = zipcodeverifierRouter

app.use(router)
app.use(store)
app.mixin(client)
app.mount('body')

