export default {
    methods: {
        changeSort(original, value) {
            console.log(original + ':' + value)
            return 'test'
            // if (original.indexOf(value) > -1 && original.indexOf('-') === -1) {
            //     return '-' + value
            // } else if (original.indexOf(value) > -1) {
            //     return value.replace('-', '')
            // }
            //
            // return value
        },
        addParamsToLocation(params) {
            for (const [key, value] of Object.entries(params)) {
                this.$route.query[key] = value
            }

            history.pushState(
                {},
                null,
                this.$route.path +
                '?' +
                Object.keys(this.$route.query)
                    .map(key => {
                        return (
                            encodeURIComponent(key) + '=' + encodeURIComponent(this.$route.query[key])
                        )
                    })
                    .join('&')
            )
        },
        shortTz(date) {
            /* Do not display daylights savings and standard in date for the US. */
            const usTz = ['EST', 'EDT', 'CST', 'CDT', 'MST', 'MDT', 'PST', 'PDT']

            for (let i = 0; i < usTz.length; i++) {
                if (date.indexOf(usTz[i]) > -1) {
                    date = date.replace('D', '').replace('S', '')
                }
            }

            return date
        },
    }
}
