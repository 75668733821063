<template>
    <div class="notification" :class="'notification--' + notification.type" v-if="notification">
        <div class="notification__close" @click="close"><i class="fas fa-times"></i></div>
        <div v-html="notification.message"></div>
    </div>
</template>

<script>
export default {
    name: 'Loader',
    props: ['notification'],
    methods: {
        close() {
            this.$emit('end')
        }
    }
}
</script>

<style lang="scss">
@import '../styles/variables';

.notification {
    border: 1px solid transparent;
    border-radius: 1rem;
    left: 14rem;
    padding: 1rem 4rem 1rem 2rem;
    position: fixed;
    right: 1rem;
    top: 4.75rem;
    z-index: 99999;

    &__close {
        border-radius: .5rem;
        cursor: pointer;
        line-height: 1.75rem;
        position: absolute;
        right: 2rem;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
        width: 1.75rem;

        svg {
            position: relative;
            top: 1px;
        }

        &:hover {
            background: rgba($black, .25);
        }
    }

    &--success {
        background: lighten($success-color, 50%);
        border-color: $success-color;
    }

    &--error {
        background: lighten($alert-color, 50%);
        border-color: $alert-color;
    }
}
</style>