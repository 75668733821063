<template>
    <Header title="settings" :actions="headerActions" @action="action"/>

    <div class="grid-container">
        <Panel header="contact info" :actions="contactActions" @action="contactAction"
               v-if="$store.state.user">
            <Loader :loading="savingContact"></Loader>

            <div class="grid-x grid-margin-x">
                <div class="cell auto">
                    <Field label="Name"
                           :value="$store.state.user.contact_name"
                           @value="updateContactNameField"/>
                </div>
                <div class="cell auto">
                    <Field label="Email"
                           :value="$store.state.user.contact_email"
                           @value="updateContactEmailField"/>
                </div>
            </div>
        </Panel>
    </div>
</template>

<script>
import client from '@/mixins/client'
import Header from '@/components/Header'
import Field from '@/components/Field'
import Panel from '@/components/Panel'
import Loader from '@/components/Loader';

export default {
    name: 'Settings',
    mixins: [client],
    components: {
        Field,
        Header,
        Panel,
        Loader
    },
    props: [
        'syncing'
    ],
    data () {
        return {
            headerActions: [
                {
                    label: 'sync data',
                    icon: 'fas fa-sync-alt',
                    callback: 'syncData',
                    rotate: false,
                    type: 'secondary'
                }
            ],
            contactActions: [
                {
                    label: 'save',
                    type: 'primary',
                    value: 'saveContact'
                }
            ],
            savingContact: false
        }
    },
    watch: {
        syncing() {
            this.headerActions[0].rotate = this.syncing;
        }
    },
    methods: {
        updateContactNameField(value) {
            this.$store.state.user.contact_name = value
        },
        updateContactEmailField(value) {
            this.$store.state.user.contact_email = value
        },
        contactAction(action) {
            this.savingContact = true

            if (action === 'saveContact') {
                this.request('PUT', '/shops/me/', {
                    contact_name: this.$store.state.user.contact_name,
                    contact_email: this.$store.state.user.contact_email
                }).then(response => {
                    if (response) {
                        this.$emit('notification', {
                            message: 'Contact information has been saved.',
                            type: 'success'
                        })
                        this.$store.state.user = response
                    } else {
                        this.$emit('notification', {
                            message: 'Something went wrong.',
                            type: 'error'
                        })
                    }

                    this.savingContact = false
                })
            }
        },
        action(action) {
            if (action === 'syncData') {
                if (this.syncing) {
                    this.$emit('notification', {
                        message: 'Cannot start a new task while one is already running.',
                        type: 'error'
                    })

                    return
                }

                this.request('GET', '/shops/sync/').then(response => {
                    if (response) {
                        this.$emit('notification', {
                            message: 'All products from Shopify are now syncing.',
                            type: 'success'
                        })
                        this.$emit('syncing')
                        this.headerActions[0].rotate = true
                    } else {
                        this.$emit('notification', {
                            message: 'Something went wrong.',
                            type: 'error'
                        })
                    }
                })
            }
        }
    },
    mounted () {

    }
}
</script>

<style lang="scss" scoped>
@import '../../styles/mixins';
@import '../../styles/variables';
</style>