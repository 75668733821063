<template>
    <TagItemSkeleton v-if="type === 'tag'"/>
    <ProductItemSkeleton v-if="type === 'product'"/>
    <CollectionItemSkeleton v-if="type === 'collection'"/>
</template>

<script>
import TagItemSkeleton from '@/pages/tags/components/ListItemSkeleton'
import ProductItemSkeleton from '@/pages/products/components/ListItemSkeleton'
import CollectionItemSkeleton from '@/pages/collections/components/ListItemSkeleton'

export default {
    name: 'AbstractSkeleton',
    props: [
        'type'
    ],
    components: {
        TagItemSkeleton,
        ProductItemSkeleton,
        CollectionItemSkeleton
    }
}
</script>

<style lang="scss">
@import '../styles/mixins';
@import '../styles/variables';

.skeleton {
    @include transition(background-color .25s);
    animation: pulse-bg 1s infinite;
    border-radius: 2rem;
    height: 20px;
    width: 100%;
}

.dark-mode {
    .skeleton {
        animation: pulse-bg-dark 1s infinite;
    }
}

@keyframes pulse-bg {
    0% {
        background-color: $light-medium-gray;
    }
    50% {
        background-color: $medium-gray;
    }
    100% {
        background-color: $dark-medium-gray;
    }
}

@keyframes pulse-bg-dark {
    0% {
        background-color: $dark-gray;
    }
    50% {
        background-color: $dark2-gray;
    }
    100% {
        background-color: $dark3-gray;
    }
}
</style>