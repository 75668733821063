<template>
    <div class="panel">
        <div class="panel__close" @click="closePanel" v-if="close">
            <i class="far fa-times"/>
        </div>

        <div class="panel__header" v-if="header">
            <h3 v-html="header"/>
        </div>

        <div class="panel__content" :class="{ 'no-padding': padding !== undefined && !padding }">
            <slot/>
        </div>

        <div class="panel__footer" v-if="actions">
            <div class="grid-x grid-padding-x align-right">
                <div class="cell shrink" v-for="(action, i) in actions" :key="i">
                    <CustomButton
                        :disabled="action.disabled"
                        icon="fas fa-plus"
                        :type="action.type"
                        v-html="action.label" v-if="action.disabled"/>
                    <CustomButton
                        icon="fas fa-plus"
                        :type="action.type"
                        @click="panelAction(action.value)"
                        v-html="action.label" v-else/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton'

export default {
    name: 'Button',
    props: [
        'header',
        'close',
        'padding',
        'actions'
    ],
    components: {
        CustomButton
    },
    methods: {
        closePanel() {
            this.$emit('close')
        },
        panelAction(action) {
            this.$emit('action', action)
        }
    }
}
</script>

<style lang="scss">
@import '../styles/mixins';
@import '../styles/variables';

.panel {
    @include shadow(0 5px 10px rgba($black, .1));
    background: $white;
    border: 1px solid $lightest-gray;
    border-radius: 2rem;
    margin-bottom: 2rem;
    position: relative;

    &__close {
        @include transition(background .2s);
        color: $dark3-gray;
        cursor: pointer;
        border-radius: 2rem;
        display: inline-block;
        font-size: 1.25rem;
        line-height: 2rem;
        position: absolute;
        right: .75rem;
        text-align: center;
        top: .75rem;
        width: 2rem;

        svg {
            position: relative;
            top: .1rem;
        }

        &:hover {
            background: $light-medium-gray;
        }
    }

    &__header {
        border-bottom: 1px solid $light-gray;
        padding: 1rem 2rem;

        h3 {
            margin: 0;
        }
    }

    &__content {
        padding: 2rem;

        &.no-padding {
            padding: 0;

            .grid-padding-x {
                margin: 0 !important;
            }
        }

        & > .grid-padding-x {
            margin-left: -0.9375rem;
            margin-right: -0.9375rem;
        }
    }

    &__footer {
        border-top: 1px solid $light-gray;
        padding: 1rem 2rem;

        .grid-x {
            margin: 0;
        }

        & > .grid-padding-x {
            margin-left: -0.9375rem;
            margin-right: -0.9375rem;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.dark-mode {
    .panel {
        background: $darker2-gray;
        border: 1px solid $darker-gray;

        &__close {
            background: $dark2-gray;
            border-color: $dark-gray;
            color: $white;

            &:hover {
                background: darken($dark2-gray, 5%);
            }
        }

        &__header {
            border-color: $darker-gray;
        }
    }
}
</style>
