<template>
    <div class="cell auto">
        <div class="grid-x align-middle">
            <div class="cell shrink">
                <div class="skeleton skeleton--image"></div>
            </div>

            <div class="cell auto">
                <div class="skeleton skeleton--1"></div>
            </div>
        </div>
    </div>
    <div class="cell auto col-small"><div class="skeleton skeleton--2"></div></div>
    <div class="cell auto col-large"><div class="skeleton skeleton--3"></div></div>
</template>

<script>
export default {
    name: 'ProductListItemSkeleton'
}
</script>

<style lang="scss" scoped>
@import '../../../styles/variables';

.skeleton {
    &--image {
        height: 3rem;
        margin-right: 1rem;
        width: 3rem;
    }

    &--1 {
        max-width: 15rem;
    }

    &--2 {
        max-width: 5rem;
    }

    &--3 {
        max-width: 10rem;
    }
}
</style>