<template>
    <span :class="'label label--' + type" v-html="label"></span>
</template>

<script>
export default {
    name: 'Label',
    props: [
        'label',
        'type'
    ]
}
</script>

<style lang="scss" scoped>
@import 'src/styles/variables';

.label {
    background: $dark-gray;
    border-radius: 2rem;
    color: $white;
    padding: .25em .75em;

    &--success {
        background: $success-color;
    }

    &--warning {
        background: $warning-color;
    }

    &--alert {
        background: $alert-color;
    }
}
</style>