<template>
    <label v-html="label" v-if="label"></label>
    <div class="field-wrapper">
        <div class="field" :class="{ 'field--icon': icon }">
            <i :class="icon" v-if="icon"/>
            <input type="text" :placeholder="placeholder" v-if="!type || type === 'text'"
                   :value="value"
                   v-on:keyup="$emit('value', $event.target.value)"
                   v-on:keyup.enter="$emit('submit', $event.target.value)"/>
            <textarea v-if="type && type === 'textarea'" :value="value"
                      v-on:keyup="$emit('value', $event.target.value)"
                      v-on:keyup.enter="$emit('submit', $event.target.value)"></textarea>
            <select v-if="type && type === 'select'" :value="value">
                <option v-for="(option, i) in options" :key="i" :value="option.value"
                        :selected="value === option.value ? 'selected' : ''"
                        v-html="option.label"></option>
            </select>
        </div>

        <div class="field-helper" v-if="helper"><small><i v-html="helper"></i></small></div>
    </div>
</template>

<script>
export default {
    name: 'Field',
    props: [
        'label',
        'value',
        'icon',
        'placeholder',
        'type',
        'options',
        'helper'
    ],
    emits: ['submit', 'value']
}
</script>

<style lang="scss" scoped>
@import '../styles/variables';

label {
    display: block;
    font-weight: 700;
    margin-bottom: .25em;
}

.field-wrapper {
    margin-bottom: 1rem;
}

.field-helper {
    margin-top: 5px;
}

.field {
    background: $light-gray;
    border: 1px solid $light-medium-gray;
    border-radius: 2rem;
    overflow: hidden;
    position: relative;

    input {
        background: none;
        border: 0;
        color: $black;
        display: block;
        height: 2.5rem;
        line-height: 2.5rem;
        padding: .5em 1rem;
        width: 100%;

        &::-webkit-input-placeholder {
            color: $dark-gray;
        }

        &:-ms-input-placeholder {
            color: $dark-gray;
        }

        &::placeholder {
            color: $dark-gray;
        }

        &:focus {
            border: 0;
            outline: 0;
        }
    }

    textarea {
        background: none;
        border: 0;
        color: $black;
        height: 10rem;
        padding: 1rem;
        width: 100%;

        &:focus {
            border: 0;
            outline: 0;
        }
    }

    &--icon {
        color: $dark3-gray;
        padding-left: 2rem;

        svg {
            position: absolute;
            top: .75rem;
            left: 1rem;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.dark-mode {
    .field {
        background: $dark2-gray;
        border-color: $dark-gray;

        input {
            color: $white;

            &::-webkit-input-placeholder {
                color: $medium-gray;
            }

            &:-ms-input-placeholder {
                color: $medium-gray;
            }

            &::placeholder {
                color: $medium-gray;
            }
        }

        textarea {
            color: $white;
        }

        &--icon {
            color: $white;
        }
    }
}
</style>