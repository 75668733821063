<template>
    <div class="table-list">
        <AbstractListFilter :type="type" @filter="filter"/>

        <Pagination :page="data && data.page ? data.page : null" @paginate="paginate"/>

        <div class="table-list__header sticky">
            <div class="grid-x grid-padding-x">
                <div class="table-list__checkbox cell" v-if="selectable">
                    <Checkbox :fill="true" :checked="allChecked" @check="checkAll"/>
                </div>

                <div class="cell auto">
                    <div class="table-list__header__items grid-x grid-padding-x align-middle">
                        <AbstractListHeader :type="type" :ordering="ordering"
                                            v-if="(actions && !showActions) || !actions"
                                            :args="headerArgs" @filter="filter" @action="action"/>
                        <ListActions :actions="actions"
                                     @action="action"
                                     @filter="filter"
                                     v-if="showActions"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="table-list__items" v-if="!loading && data.data.length > 0">
            <div class="table-list__item grid-x grid-padding-x"
                 v-for="(object, i) in data.data" :key="i">
                <div class="table-list__checkbox cell" v-if="selectable">
                    <Checkbox :fill="true" :checked="selectedItems.indexOf(object.id) > -1"
                              @check="checkItem(object.id)"/>
                </div>

                <div class="cell auto">
                    <AbstractListItem
                        :classes="'table-list__item--' + type"
                        :object="object"
                        :type="type"/>
                </div>
            </div>
        </div>

        <div class="table-list__items" v-if="loading && skeletonCount">
            <div class="table-list__item table-list__item--skeleton grid-x grid-padding-x align-middle"
                 :class="'table-list__item--' + type"
                 v-for="i in skeletonCount" :key="i">
                <AbstractSkeleton :type="type"/>
            </div>
        </div>

        <div class="table-list__items" v-if="!loading && data.data.length === 0">
            <div class="table-list__item grid-x grid-padding-x">
                <div class="cell">
                    <div class="grid-x grid-padding-x align-middle">
                        <div class="cell auto">no objects found...</div>
                    </div>
                </div>
            </div>
        </div>

        <Pagination :page="data && data.page ? data.page : null" @paginate="paginate"/>
    </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import AbstractListFilter from '@/components/AbstractListFilter'
import AbstractListHeader from '@/components/AbstractListHeader'
import ListActions from '@/components/ListActions'
import AbstractListItem from '@/components/AbstractListItem'
import AbstractSkeleton from '@/components/AbstractSkeleton'
import Checkbox from '@/components/Checkbox'

export default {
    name: 'TableList',
    props: [
        'actions',
        'type',
        'ordering',
        'data',
        'selectable',
        'loading'
    ],
    components: {
        Pagination,
        AbstractListFilter,
        AbstractListHeader,
        ListActions,
        AbstractListItem,
        AbstractSkeleton,
        Checkbox
    },
    data() {
        return {
            skeletonCount: 3,
            page: null,
            showActions: false,
            filters: {},
            allChecked: false,
            selectedItems: [],
            headerArgs: {},
        }
    },
    watch: {
        'data': function () {
            if (this.data.data)
                this.skeletonCount = this.data.data.length
            this.allChecked = false
            this.selectedItems = []
            this.showActions = false
        }
    },
    methods: {
        checkAll() {
            if ((this.selectedItems.length === this.data.data.length) && this.allChecked) {
                this.allChecked = false
                this.showActions = false
                this.selectedItems = []
            } else {
                this.allChecked = true
                this.showActions = !!this.actions
                this.selectedItems = []

                for (let i = 0; i < this.data.data.length; i++) {
                    this.selectedItems.push(this.data.data[i].id)
                }
            }

            this.headerArgs['selectedCount'] = this.data.page.total
            this.itemsSelected()
        },
        checkItem(id) {
            let arrayIndex = this.selectedItems.indexOf(id)

            if (arrayIndex > -1) {
                this.selectedItems.splice(arrayIndex, 1)
            } else {
                this.selectedItems.push(id)
            }

            if (this.selectedItems.length < this.data.data.length) {
                this.allChecked = false
            }

            this.headerArgs['selectedCount'] = this.selectedItems.length
            this.showActions = this.selectedItems.length > 0 && !!this.actions;
            this.itemsSelected()
        },
        itemsSelected() {
            this.$emit('select', this.selectedItems)
        },
        paginate(page) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });

            this.page = page
            this.filters.page = this.page
            this.$emit('filter', this.filters)
        },
        action(action) {
            this.$emit('action', action, this.selectedItems)
        },
        filter(data = {}) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });

            this.filters = data
            if (this.page) {
                this.filters.page = this.page
            }
            this.$emit('filter', this.filters)
        }
    }
}
</script>

<style lang="scss">
@import '../styles/variables';
@import '../styles/mixins';

.table-list {
    font-family: $secondary-font;
    line-height: 1;
    position: relative;

    &.panel {
        padding: 0;
    }

    &__header {
        background: $white;
        border-bottom: 1px solid $light-gray;
        color: $black;
        font-size: 15px;
        font-weight: 900;
        z-index: 9;

        &__items {
            position: relative;

            & > .cell {
                padding: 1rem 0;
            }
        }

        &.fixed {
            top: 68px;
            position: -webkit-sticky;
            position: sticky;

            &:after {
                background: $dark-medium-gray;
                //@include gradient(rgba($black, .25), transparent);
                bottom: -2px;
                content: '';
                height: 2px;
                left: 0;
                position: absolute;
                right: 0;
                z-index: 9;
            }
        }
    }

    &__items {
        font-size: 14px;

        .cell,
        a .cell {
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    &__item {
        @include transition(background .2s);
        border-bottom: 1px solid $light-gray;
        color: $black;
        cursor: pointer;

        & > .cell {
            padding: .5rem 0;
        }

        &__image {
            background: $white;
            border: 1px solid $medium-gray;
            border-radius: 2rem;
            height: 3rem;
            margin-right: 1rem;
            overflow: hidden;
            position: relative;
            width: 3rem;

            div {
                background: $medium-gray;
                border: 1px solid $light-gray;
                height: 3rem;
                color: $dark3-gray;
                font-size: 1.5rem;
                position: relative;
                text-align: center;
                width: 3rem;

                svg {
                    @include transform(translate(-50%, -50%));
                    left: 50%;
                    position: absolute;
                    top: 50%;
                }
            }

            img {
                bottom: -9999px;
                left: -9999px;
                margin: auto;
                position: absolute;
                right: -9999px;
                top: -9999px;
            }
        }

        &:hover {
            background: darken($white, 3%);
        }

        &--tag {
            padding: 1rem 0;

            &.table-list__item--skeleton {
                padding: calc(1rem - 3px) 0;
            }
        }
    }

    &__checkbox.cell {
        display: flex;
        flex: 0 0 3rem;
        min-height: 49px;
        position: relative;
        width: 3rem;
    }
}

.dark-mode {
    .table-list {
        &__header {
            background: $darker2-gray;
            border-color: $darker-gray;
            color: $white;
        }

        &__item {
            border-color: $darker-gray;
            color: $white;

            &__image {
                border-color: $dark2-gray;

                div {
                    background: $dark2-gray;
                    color: $darkest-gray;
                }
            }

            &:hover {
                background: darken($darker2-gray, 1%);
            }
        }
    }
}
</style>