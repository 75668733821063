<template>
    <div class="grid-x grid-padding-x align-middle" :class="classes">
        <div class="cell auto">
            <div class="grid-x align-middle">
                <div class="cell shrink">
                    <div class="table-list__item__image">
                        <img :src="item.featured_image.thumb" :alt="item.title + ' Image'"
                             v-if="item.featured_image">
                        <div v-else><i class="fa-image fas"/></div>
                    </div>
                </div>

                <div class="cell auto">
                    {{ item.title }}
                </div>
            </div>
        </div>
        <div class="cell auto col-small text-accent-color" v-html="item.tag_count_display"></div>
        <div class="cell auto col-large" v-html="item.published_at"></div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: 'CollectionListItem',
    props: [
        'object',
        'classes'
    ],
    data() {
        return {
            item: this.object
        }
    },
    mounted() {
        this.item.tag_count_display = this.item.tag_count + ' tags'

        if (this.item.tag_count === 1) {
            this.item.tag_count_display = this.item.tag_count + ' tag'
        }

        this.item.published_at = moment(this.object.published_at).format(
            'MMM D, YYYY [at] h:mma')
    }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/variables';
</style>