import { createWebHistory, createRouter } from 'vue-router'

import Home from '@/apps/zipcodeverifier/Home'
// import Customize from '@/apps/zipcodeverifier/Customize'
import PrivacyPolicy from '@/apps/zipcodeverifier/PrivacyPolicy'
import TermsService from '@/apps/zipcodeverifier/TermsService'
import Settings from '@/apps/zipcodeverifier/Settings'
import Apps from '@/pages/apps/List'

const index = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    // {
    //     path: '/customize/',
    //     name: 'Customize',
    //     component: Customize,
    // },
    {
        path: '/privacy-policy/',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
    },
    {
        path: '/terms-service/',
        name: 'TermsService',
        component: TermsService,
    },
    {
        path: '/settings/',
        name: 'Settings',
        component: Settings,
    },
    {
        path: '/apps/',
        name: 'Apps',
        component: Apps,
    }
];

const zipcodeverifierRouter = createRouter({
    history: createWebHistory(),
    routes: index,
});

export default zipcodeverifierRouter;
