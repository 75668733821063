<template>
    <Header title="Terms of Service"/>

    <div class="grid-container">
        <Panel>
            <p>By signing up for the Product Tags by Blissful service or any of the
                services of Product Tags by Blissful, you are agreeing to be bound by the
                following terms and conditions. Any new features or tools which are
                added to the current Service shall be also subject to the Terms of
                Service. You can review the current version of the Terms of Service
                at any time at here. Product Tags by Blissful reserves the right to update
                and change the Terms of Service by posting updates and changes to
                the Product Tags by Blissful website. You are advised to check the Terms
                of Service from time to time for any updates or changes that may
                impact you.
            </p>

            <p>You must read, agree with and accept all of the terms and
                conditions contained in this Terms of Service agreement and Product Tags by Blissful’s
                Privacy Policy and, if applicable, Data Processing
                Addendum (“DPA”) before you may become a Product Tags by Blissful user.
            </p>

            <p>Everyday language summaries are provided for your benefit and are
                not legally binding. Please read the Terms of Service for the
                complete picture of your legal requirements. By using Product Tags by Blissful or any
                Product Tags by Blissful services, you are agreeing to
                these terms. Be sure to occasionally check
            </p>

            <h2>Account terms</h2>

            <ol>
                <li>You must be 18 years or older or at least the age of majority in
                    the jurisdiction where you reside or from which you use this
                    Service.
                </li>

                <li>To access and use the Services, you must register for a Services
                    account (“Account”) by providing your Shopify store data
                    including, but not limited to store information, product data,
                    order data, and any other information indicated as required.
                    Product Tags by Blissful may cancel an existing Account, for any reason,
                    in our sole discretion.
                </li>

                <li>You acknowledge that Product Tags by Blissful will use the email
                    address on your store as the primary method for communication
                    and/or our live chat service.
                </li>

                <li>You are responsible for keeping your Shopify password secure,
                    which is your means of entering the Product Tags by Blissful App
                    Service. Product Tags by Blissful cannot and will not be liable for any
                    loss or damage from your failure to maintain the security of your
                    account and password.
                </li>

                <li>You are responsible for all activity and content such as photos,
                    images, videos, graphics, written content, audio files, code,
                    information, or data uploaded, collected, generated, stored,
                    displayed, distributed, transmitted or exhibited on or in
                    connection with your Account (“Materials”).
                </li>

                <li>A breach or violation of any term in the Terms of Service as
                    determined in the sole discretion of Product Tags by Blissful will
                    result in an immediate termination of your services.
                </li>
            </ol>

            <p><strong>Which means</strong></p>

            <p>Don’t use Product Tags by Blissful for anything illegal or transmit any
                harmful code. Remember that with any violation of these terms we
                will cancel your service.</p>

            <p>If we need to reach you, we will send you an email.</p>

            <h2>General conditions</h2>
            <p>You must read, agree with and accept all of the terms and
                conditions contained in this Terms of Service and the Privacy Policy
                before you may become a member of Product Tags by Blissful.</p>

            <ol>
                <li>Technical support is only provided to paying account holders and
                    is only available via email or live chat service.
                </li>

                <li>The Terms of Service shall be governed by and interpreted in
                    accordance with the laws of the State of Idaho and the laws of the
                    United States applicable therein, without regard to principles of
                    conflicts of laws. The parties irrevocably and unconditionally
                    submit to the exclusive jurisdiction of the courts of the State of
                    Idaho with respect to any dispute or claim arising out of or in
                    connection with the Terms of Service. The United Nations
                    Convention on Contracts for the International Sale of Goods will
                    not apply to these Terms of Service and is hereby expressly
                    excluded.
                </li>

                <li>You acknowledge and agree that Product Tags by Blissful may amend
                    these Terms of Service at any time by posting the relevant amended
                    and restated Terms of Service on the Product Tags by Blissful app legal pages inside
                    the app.
                    and such amendments to the Terms of Service are effective as of the date of
                    posting. Your continued use of the Services after the amended
                    Terms of Service are posted to the Product Tags by Blissful Payments
                    website constitutes your agreement to, and acceptance of, the
                    amended Terms of Service. If you do not agree to any changes to
                    the Terms of Service, do not continue to use the Service.
                </li>

                <li>You may not use the Service for any illegal or unauthorized
                    purpose nor may you, in the use of the Service, violate any laws
                    in your jurisdiction (including but not limited to copyright laws)
                    as well as the laws of the United States and the State of Idaho.
                </li>

                <li>The Service in some cases provides “API Credentials” (the secret
                    key and access token that allow you to make authenticated requests
                    to the Service API). You are solely responsible for the activity
                    that occurs using your API Credentials and for keeping your API
                    Credentials secure.
                </li>

                <li>You agree not to reproduce, duplicate, copy, sell, resell or
                    exploit any portion of the Service, use of the Service, or access
                    to the Service without the express written permission by Vendor
                    Consignment.
                </li>

                <li>You shall not purchase search engine or other pay per click
                    keywords (such as Google AdWords), or domain names that use Blissful
                    Apps, Product Tags by Blissful trademarks and/or variations and misspellings thereof.
                </li>

                <li>Questions about the Terms of Service should be sent to
                    support@blissfulapps.com.
                </li>

                <li>You understand that your Materials (not including credit card
                    information), may be transferred unencrypted and involve (a)
                    transmissions over various networks; and (b) changes to conform
                    and adapt to technical requirements of connecting networks or
                    devices. Credit Card information is always encrypted during
                    transfer over networks.
                </li>

                <li>You acknowledge and agree that your use of the Service,
                    including information transmitted to or stored by Vendor
                    Consignment, is governed by its privacy policy at
                    <router-link :to="{ name: 'PrivacyPolicy' }">privacy policy</router-link>
                </li>
            </ol>
            <p><strong>Which means</strong></p>

            <p>The Product Tags by Blissful Service belongs to us. You are not allowed
                to rip it off or use it for any illegal or sketchy purpose.</p>

            <p>Your Materials may be transferred unencrypted and may be altered,
                but credit card information is always encrypted.</p>

            <ol>
                <li>Product Tags by Blissful rights</li>

                <li>We reserve the right to modify or terminate the Service for any
                    reason, without notice at any time.
                </li>

                <li>We reserve the right to refuse service to anyone for any reason
                    at any time.
                </li>

                <li>We may, but have no obligation to, remove Materials and Accounts
                    containing content that we determine in our sole discretion are
                    unlawful, offensive, threatening, libelous, defamatory,
                    pornographic, obscene or otherwise objectionable or violates any
                    party’s intellectual property or these Terms of Service.
                </li>

                <li>Verbal or written abuse of any kind (including threats of abuse
                    or retribution) of any Product Tags by Blissful customer, Vendor
                    Consignment employee, member, or officer will result in immediate
                    account termination.
                </li>

                <li>Product Tags by Blissful does not pre-screen Materials and it is in
                    their sole discretion to refuse or remove any Materials that is
                    available via the Service.
                </li>

                <li>We reserve the right to provide our services to your competitors
                    and make no promise of exclusivity in any particular market
                    segment. You further acknowledge and agree that Product Tags by Blissful
                    employees and contractors may also be Product Tags by Blissful
                    customers/merchants and that they may compete with you, although
                    they may not use your confidential information in doing so.
                </li>

                <li>Product Tags by Blissful retains the right to determine, in our sole
                    judgment, rightful account ownership and transfer an account to
                    the rightful owner. If we are unable to reasonably determine the
                    rightful account owner, Product Tags by Blissful reserves the right to
                    temporarily disable an account until resolution has been
                    determined between the disputing parties.
                </li>
            </ol>
            <p><strong>Which means</strong></p>

            <p>We can modify, cancel or refuse the service at anytime.</p>

            <p>In the event of an ownership dispute over a Product Tags by Blissful
                account, we can freeze the account or transfer it to the rightful
                owner.</p>
            <h2>Limitation of liability</h2>

            <ol>
                <li>You expressly understand and agree that Product Tags by Blissful shall
                    not be liable for any direct, indirect, incidental, special,
                    consequential or exemplary damages, including but not limited to,
                    damages for loss of profits, goodwill, use, data or other
                    intangible losses resulting from the use of or inability to use
                    the service.
                </li>

                <li>In no event shall Product Tags by Blissful or our suppliers be liable
                    for lost profits or any special, incidental or consequential
                    damages arising out of or in connection with our site, our
                    services or this agreement (however arising including negligence).
                    You agree to indemnify and hold us and (as applicable) our parent,
                    subsidiaries, affiliates, Product Tags by Blissful partners, officers,
                    directors, agents, and employees, harmless from any claim or
                    demand, including reasonable attorneys fees, made by any third
                    party due to or arising out of your breach of this Agreement or
                    the documents it incorporates by reference, or your violation of
                    any law or the rights of a third party.
                </li>

                <li>In no event shall Product Tags by Blissful or our suppliers be liable
                    for inaccurate calculations. Commission calculations are the sole
                    responsibility of you.
                </li>

                <li>In no event shall Product Tags by Blissful or our suppliers be liable
                    for PayPal transactions in anyway. Payments and refunds of any
                    kind are the sole responsibility of you and will need to be
                    addressed directly with PayPal.
                </li>

                <li>Your use of the Service is at your sole risk. The Service is
                    provided on an as is and as available basis without any warranty
                    or condition, express, implied or statutory.
                </li>

                <li>Product Tags by Blissful does not warrant that the Service will be
                    uninterrupted, timely, secure, or error-free.
                </li>

                <li>Product Tags by Blissful does not warrant that the results that may be
                    obtained from the use of the Service will be accurate or reliable.
                </li>

                <li>Product Tags by Blissful does not warrant that the quality of any
                    products, services, information, or other material purchased or
                    obtained by you through the Service will meet your expectations,
                    or that any errors in the Service will be corrected.
                </li>
            </ol>

            <p><strong>Which means</strong></p>

            <p>We are not responsible if you break the law, breach this agreement
                or go against the rights of a third party, especially if you get
                sued.</p>

            <p>Service is as is so it may have errors or interruptions and we
                provide no warranties.</p>

            <h2>Waiver and complete agreement</h2>

            <p>The failure of Product Tags by Blissful to exercise or enforce any right
                or provision of the Terms of Service shall not constitute a waiver
                of such right or provision. The Terms of Service constitutes the
                entire agreement between you and Product Tags by Blissful and govern your
                use of the Service, superseding any prior agreements between you and
                Product Tags by Blissful (including, but not limited to, any prior
                versions of the Terms of Service).</p>

            <p><strong>Which means</strong></p>

            <p>If Product Tags by Blissful chooses not to enforce any of these
                provisions at any time, it does not mean that they give up that
                right later.</p>

            <p>These terms of service make up the agreement that applies to you.
                This means that any previous agreements between you and Product Tags by Blissful don’t
                apply if they conflict with these terms.</p>

            <h2>Intellectual property and customer content</h2>

            <ol>
                <li>We do not claim any intellectual property rights over the
                    Materials you provide to the Service. All Materials you upload
                    remains yours. You can remove your Services store at any time by
                    deleting your Account.
                </li>

                <li>By uploading Materials, you agree: (a) to allow other internet
                    users to view your Materials; (b) to allow Product Tags by Blissful to
                    display and store your Materials; and (c) that Product Tags by Blissful
                    can, at any time, review all the Materials submitted by you to its
                    Service.
                </li>

                <li>You retain ownership over all Materials that you upload to a
                    Services store; however, by making your store public, you agree to
                    allow others to view your Materials. You are responsible for
                    compliance of Materials with any applicable laws or regulations.
                </li>

                <li>We will not disclose your confidential information to third
                    parties, except as required in the course of providing our
                    services. Confidential information includes any Materials or
                    information provided by you to us which is not publicly known.
                    Confidential information does not include information that: (a)
                    was in the public domain at the time we received it; (b) comes
                    into the public domain after we received it through no fault of
                    ours; (c) we received from someone other than you without breach
                    of our or their confidentiality obligations; or (d) we are
                    required by law to disclose.
                </li>

                <li>Product Tags by Blissful shall have the non-exclusive right and
                    license to use the names, trademarks, service marks and logos
                    associated with your store to promote the Service.
                </li>
            </ol>

            <p><strong>Which means</strong></p>

            <p>Anything you upload remains yours and your responsibility.</p>

            <h2>Payment of fees</h2>

            <ol>
                <li>A valid Shopify membership is required to fulfill recurring
                    charges for the Product Tags by Blissful App.
                </li>

                <li>The service will be billed in 1 month intervals via Shopify
                    billing. Users have approximately two weeks to bring up and settle
                    any issues with the billing.
                </li>

                <li>All fees are exclusive of applicable federal, provincial, state,
                    local or other governmental sales, goods and services, harmonized
                    or other taxes, fees or charges now in force or enacted in the
                    future (Taxes).
                </li>

                <li>Product Tags by Blissful does not provide refunds except for under
                    extreme circumstances under the descretion of Blissful Apps.
                </li>
            </ol>

            <p><strong>Which means</strong></p>

            <p>For live payment gateways, a valid credit card is required. You
                will be charged every 30 days your base fee and percentage fee. Tax
                is not included and will be billed to your credit card. No
                refunds.</p>

            <h2>Cancellation and termination</h2>

            <ol>
                <li>You may cancel your account at anytime by uninstalling the
                    Product Tags by Blissful App.
                </li>

                <li>Upon termination of the Services by either party for any reason:
                    <ol>
                        <li>Product Tags by Blissful will cease providing you with the
                            Services and you will no longer be able to access your
                            Account;
                        </li>
                        <li>unless otherwise provided in the Terms of Service, you will
                            not be entitled to any refunds of any Fees, pro rata or
                            otherwise;
                        </li>
                        <li>any outstanding balance owed to Product Tags by Blissful for your
                            use of the Services through the effective date of such
                            termination will immediately become due and payable in full.
                        </li>
                    </ol>
                </li>

                <li>If you cancel the Service in the middle of the month, you will
                    receive one final billing. Once that billing has been paid you
                    will not be charged again.
                </li>

                <li>We reserve the right to modify or terminate the Vendor
                    Consignment service for any reason, without notice at any time.
                </li>

                <li>Fraud: Without limiting any other remedies, Product Tags by Blissful
                    may suspend or terminate your account if we suspect that you (by
                    conviction, settlement, insurance or escrow investigation, or
                    otherwise) have engaged in fraudulent activity in connection with
                    the Site.
                </li>
            </ol>

            <p><strong>Which means</strong></p>

            <p>To initiate a cancellation, simply uninstall the Product Tags by Blissful
                App.</p>

            <p>We may change or cancel your account at any time. Any fraud and we
                will suspend or cancel your account.</p>

            <h2>Modifications to the service and prices</h2>

            <ol>
                <li>Prices for using Product Tags by Blissful are subject to change at any
                    time and without notice from Product Tags by Blissful.
                </li>

                <li>Product Tags by Blissful reserves the right at any time to time to
                    modify or discontinue, the Service (or any part thereof) with or
                    without notice.
                </li>

                <li>Product Tags by Blissful shall not be liable to you or to any third
                    party for any modification, price change, suspension or
                    discontinuance of the Service.
                </li>
            </ol>

            <p><strong>Which means</strong></p>

            <p>We may change or discontinue the service at anytime, without
                liability.</p>

            <h2>Legal responsibility</h2>
            <p>Product Tags by Blissful assumes no responsibility nor warranty regarding
                meeting applicable disclosure requirements in your customer
                communications and notifications, including but not limited to state
                and federal requirements. You are solely responsible for keeping
                such disclosure requirements in compliance with applicable
                authorities.</p>

            <p><strong>Which means</strong></p>

            <p>Make sure you provide in relevant email notifications the necessary
                subscription related disclosures for state and other authorities
                that require them.</p>

            <h2>Privacy & Data Protection</h2>
            <p>Product Tags by Blissful is firmly committed to protecting the privacy of
                your personal information and the personal information of your
                customers. By using the Service, you acknowledge and agree that
                Product Tags by Blissful’s collection, usage and disclosure of this
                personal information is governed by our Privacy Policy.</p>

            <p>Additionally, if: (a) you are established in the European Economic
                Area (EEA); (b) you provide goods or services to customers in the
                EEA; or (c) you are otherwise subject to the requirements of the EU
                General Data Protection Regulation, Product Tags by Blissful’s collection
                and use of personal information of any European residents is also
                subject to our Data Processing Addendum</p>
        </Panel>
    </div>
</template>

<script>
import Header from '@/components/Header'
import Panel from '@/components/Panel'

export default {
    name: 'TermsService',
    components: {
        Header,
        Panel
    }
}
</script>

<style lang="scss">
@import '../../styles/variables';
@import '../../styles/mixins';
</style>
