<template>
    <div class="search-bar">
        <Field placeholder="start typing to search" icon="fa-search far"
               :value="searchValue"
               @submit="search"></Field>
    </div>
</template>


<script>
import utils from '@/mixins/utils'
import Field from '@/components/Field'

export default {
    name: 'Search',
    mixins: [
        utils
    ],
    components: {
        Field
    },
    data() {
        return {
            searchValue: ''
        }
    },
    emits: ['filter'],
    methods: {
        search(value) {
            this.searchValue = value

            if (this.$route.query.page) {
                this.$route.query.page = 1
            }

            this.addParamsToLocation({
                search: value
            })

            if (value) {
                this.$emit('filter', {
                    'search': value
                })
            } else {
                this.$emit('filter')
            }
        }
    },
    mounted() {
        this.searchValue = this.$route.query.search
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/variables';

.search-bar {
    padding: 1rem 0 .5rem;
}
</style>