<template>
    <Header title="home"/>

    <div class="grid-container">
<!--        <div class="grid-x grid-padding-x">-->
<!--            <div class="cell auto">-->
<!--                <Panel header="last 3 hours">-->
<!--                    <h2>100</h2>-->
<!--                </Panel>-->
<!--            </div>-->

<!--            <div class="cell auto">-->
<!--                <Panel header="last 24 hours">-->
<!--                    <h2>100</h2>-->
<!--                </Panel>-->
<!--            </div>-->

<!--            <div class="cell auto">-->
<!--                <Panel header="lifetime">-->
<!--                    <h2>100</h2>-->
<!--                </Panel>-->
<!--            </div>-->
<!--        </div>-->

        <Panel :actions="settingsActions" header="popup" @action="settingsAction">
            <Loader :loading="savingSettings"></Loader>

            <div class="grid-x align-middle">
                <div class="cell shrink">
                    <Checkbox :checked="settings && settings.active" @check="checkActive"/>
                </div>

                <div class="cell auto">
                    <strong style="display: inline-block; padding-left: 10px; position: relative; top: -2px;">
                        display on store</strong>
                </div>
            </div>
        </Panel>

        <Panel :actions="zipcodeActions" header="Add zipcodes" @action="panelAction">
            <Loader :loading="savingZipcodes"></Loader>

            <Field helper="Press enter to set zipcodes. You can enter mutliple zipcodes at a time using a comma to seperate them."
                   label="Zipcodes"
                   placeholder="97100, 97101, 97102..."
                   :value="currentZipcodes"
                   @value="setZipcodes"
                   @submit="addZipcodes"/>

            <div v-if="zipcodes.length > 0">
                <span class="label" v-for="(zipcode, i) in zipcodes" :key="i">
                    {{ zipcode }} <span @click="removeZipcode(zipcode)">
                    <i class="fas fa-times"></i></span>
                </span>
            </div>
        </Panel>
    </div>
</template>

<script>
import Header from '@/components/Header'
import Loader from '@/components/Loader'
import Panel from '@/components/Panel'
import Field from '@/components/Field'
import Checkbox from '@/components/Checkbox'

export default {
    name: 'Home',
    components: {
        Header,
        Loader,
        Panel,
        Field,
        Checkbox
    },
    data () {
        return {
            zipcodeActions: [
                {
                    label: 'Save',
                    type: 'primary',
                    value: 'saveZipcodes'
                }
            ],
            settingsActions: [
                {
                    label: 'Save',
                    type: 'primary',
                    value: 'saveSettings'
                }
            ],
            savingSettings: false,
            savingZipcodes: false,
            currentZipcodes: '',
            zipcodes: [],
            settings: null
        }
    },
    methods: {
        panelAction(action) {
            if (action === 'saveZipcodes') {
                this.saveZipcodes()
            }
        },
        settingsAction(action) {
            if (action === 'saveSettings') {
                this.saveSettings()
            }
        },
        checkActive() {
            this.settings.active = !this.settings.active
        },
        setZipcodes(zipcodes) {
            this.currentZipcodes = zipcodes
        },
        addZipcodes(newZipcodes) {
            let zipcodeList = newZipcodes.split(',')

            for (let i = 0; i < zipcodeList.length; i++) {
                let zipcode = zipcodeList[i].trim()

                if (this.zipcodes.indexOf(zipcode) < 0 && zipcode.length > 0) {
                    this.zipcodes.push(zipcode)
                }
            }

            this.currentZipcodes = ''
        },
        removeZipcode(zipcode) {
            this.zipcodes.splice(this.zipcodes.indexOf(zipcode), 2)
        },
        saveZipcodes() {
            if (this.zipcodes.length === 0) {
                this.$emit('notification', {
                    message: 'You have not entered any zipcodes. Press enter on your keyboard once you have typed in your zipcodes.',
                    type: 'error'
                })
            } else {
                this.savingZipcodes = true
                
                this.request('PUT', '/zipcodes/', {
                    zipcodes: this.zipcodes
                }).then(response => {
                    this.savingZipcodes = false

                    if (response) {
                        this.zipcodes = response.zipcodes
                        this.$emit('notification', {
                            message: 'Zipcodes have been saved.',
                            type: 'success'
                        })
                    } else {
                        this.$emit('notification', {
                            message: 'Something went wrong. Try again later.',
                            type: 'error'
                        })
                    }
                })
            }
        },
        getZipcodes() {
            this.request('GET', '/zipcodes/').then(response => {
                if (response) {
                    this.zipcodes = response.zipcodes
                }
            })
        },
        saveSettings() {
            this.savingSettings = true

            this.request('PUT', '/zipcodes/settings/' + this.settings.id + '/', {
                active: this.settings.active
            }).then(response => {
                if (response) {
                    this.settings = response
                    this.savingSettings = false

                    this.$emit('notification', {
                        message: 'Settings have been saved.',
                        type: 'success'
                    })
                } else {
                    this.$emit('notification', {
                        message: 'Something went wrong. Try again later.',
                        type: 'error'
                    })
                }
            })
        },
        getSettings() {
            this.request('GET', '/zipcodes/settings/').then(response => {
                if (response) {
                    this.settings = response
                }
            })
        }
    },
    mounted () {
        this.getSettings()
        this.getZipcodes()
    }
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
.label {
    background: $secondary-color;
    border-radius: 1rem;
    color: #fff;
    font-size: .75rem;
    letter-spacing: 0;
    margin-right: .5rem;
    overflow: hidden;
    padding: 5px 25px 5px 8px;
    position: relative;

    span {
        background: $secondary-color;
        border-radius: 0 1rem 1rem 0;
        bottom: 0;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        width: 25px;

        svg {
            position: absolute;
            right: 9px;
            top: 7px;
        }

        &:hover {
            background: $secondary-dark-color;
        }
    }
}
</style>