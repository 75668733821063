<template>
    <Header title="Privacy Policy"/>

    <div class="grid-container">
        <Panel>
            <p>Zipcode Verifier by Blissful "the App" provides a popup to verify customer zipcode
                "the Service" to merchants who use Shopify to power their stores.
                This Privacy Policy describes how personal information is collected, used, and
                shared when you install or use the App in connection with your Shopify-supported
                store.</p>
            <p>&nbsp;</p>

            <h2>Personal Information the App Collects</h2>
            <p>When you install the App, we are automatically able to access certain types of
                information from your Shopify account:</p>

            <div class="indent">
                <h3>API Scopes</h3>
                <p>You can read more about Shopify API scopes here:
                    https://help.shopify.com/en/api/getting-started/authentication/oauth/scopes</p>

                <ul>
                    <li>write_script_tags: We use this to render javacscript on your website for the
                        pop verifier.</li>
                </ul>

                <h3>Webhooks</h3>
                <p>You can read more about Shopify webhooks here:
                    https://help.shopify.com/en/manual/sell-online/notifications/webhooks</p>

                <ul>
                    <li>Shop</li>
                    <li>shop/update, app/uninstalled</li>
                    <li>We utilize these to always keep your shop info up to date as well as update
                        our system when you uninstall the app.
                    </li>
                </ul>

                <p>We collect personal information directly from the relevant individual, through
                    your Shopify account, or using the following technologies: "Cookies" are data
                    files that are placed on your device or computer and often include an anonymous
                    unique identifier. For more information about cookies, and how to disable
                    cookies, visit https://www.allaboutcookies.org. "Log files" track actions
                    occurring on the Site, and collect data including your IP address, browser type,
                    Internet service provider, referring/exit pages, and date/time stamps. "Web
                    beacons," "tags," and "pixels" are electronic files used to record information
                    about how you browse the Site.</p>
            </div>
            <p>&nbsp;</p>

            <h2>How Do We Use Your Personal Information?</h2>
            <p>We use the personal information we collect from you and your customers in order to
                provide the Service and to operate the App. Additionally, we use this personal
                information to: Communicate with you; Optimize or improve the App; and Provide you
                with information or advertising relating to our products or services.</p>
            <p>&nbsp;</p>

            <h2>Sharing Your Personal Information</h2>
            <p>We have a philosophy about personal data and that it belongs to the person and that
                person should have full power over what is out there and to remove it if they
                choose. We do not share your data for any reason unless to comply with applicable
                laws and regulations, to respond to a subpoena, search warrant or other lawful
                request for information we receive, or to otherwise protect our rights.</p>
            <p>Your Rights If you are a European resident, you have the right to access personal
                information we hold about you and to ask that your personal information be
                corrected, updated, or deleted. If you would like to exercise this right, please
                contact us through the contact information below.</p>
            <p>Additionally, if you are a European resident we note that we are processing your
                information in order to fulfill contracts we might have with you (for example if you
                make an order through the Site), or otherwise to pursue our legitimate business
                interests listed above. Additionally, please note that your information will be
                transferred outside of Europe, including to Canada and the United States.</p>
            <p>&nbsp;</p>

            <h2>Privacy Policy Update</h2>
            <p>We may update this privacy policy from time to time in order to reflect, for example,
                changes to our practices or for other operational, legal or regulatory reasons.
                Please check back in to keep yourself informed of our policies.</p>
            <p>&nbsp;</p>

            <h2>Contacting Us</h2>
            <p>Contact Us For more information about our privacy practices, if you have questions,
                or if you would like to make a complaint, please contact us by e-mail at
                support@blissfulapps.com.</p>
        </Panel>
    </div>
</template>

<script>
import Header from '@/components/Header'
import Panel from '@/components/Panel'

export default {
    name: 'PrivacyPolicy',
    components: {
        Header,
        Panel
    }
}
</script>

<style lang="scss" scoped>
@import '../../styles/mixins';
@import '../../styles/variables';
</style>
