<template>
    <ProductTagsMenu v-if="currentApp === 'producttags.blissfulapps.com'"/>
    <ZipcodeVerifierMenu v-if="currentApp === 'zipcodeverifier.blissfulapps.com'"/>
</template>

<script>
import { APP } from '@/local'
import ProductTagsMenu from '@/apps/producttags/Menu'
import ZipcodeVerifierMenu from '@/apps/zipcodeverifier/Menu'

export default {
    name: 'Menu',
    components: {
        ProductTagsMenu,
        ZipcodeVerifierMenu
    },
    data () {
        return {
            currentApp: APP
        }
    },
    methods: {
    }
}
</script>

<style lang="scss">
@import '../styles/variables';

.app-menu {
    //background: $light-medium-gray;
    //border-right: 1px solid $medium-gray;
    bottom: 0;
    display: inline-block;
    left: 0;
    overflow-y: scroll;
    padding: 1rem 0 2rem;
    position: fixed;
    top: 0;
    width: 13rem;

    hr {
        background: $dark-medium-gray;
        border: 0;
        margin: 2rem 0;
        height: 1px;
    }

    ul {
        margin-bottom: 60px;
        padding: 0;

        li {
            margin-bottom: .5em;

            a {
                border-right: 3px solid transparent;
                color: $black;
                display: block;
                font-family: $primary-font;
                font-weight: 500;
                line-height: 1;
                padding: .5em 1.25rem;
                text-decoration: none;

                span {
                    display: inline-block;
                    font-size: .75rem;
                    margin-right: .5rem;
                    text-align: center;
                    width: 1rem;
                }

                svg {
                    margin-right: .25em;
                    position: relative;
                    top: .05em;
                }
            }

            &.active {
                a {
                    color: $secondary-color;
                    //border-color: $secondary-color;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .logo {
        color: $primary-color;
        font-family: $primary-font;
        font-size: 2rem;
        font-style: italic;
        font-weight: 900;
        line-height: .75;
        padding: 1rem 1.25rem;

        span {
            color: $secondary-color;
            font-size: 1rem;
            font-weight: 700;
            letter-spacing: 0;
        }
    }

    .legal {
        font-size: .75rem;
    }
}

.dark-mode {
    .app-menu {
        background: $darkest-gray;
        border-right: 1px solid $darker2-gray;

        hr {
            background: $darker2-gray;
        }

        ul {
            li {
                a {
                    color: $white;
                }

                &.active {
                    a {
                        color: $secondary-lighter-color;
                        border-color: $secondary-lighter-color;
                    }
                }
            }
        }

        .logo {
            span {
                color: $secondary-lighter-color;
            }
        }
    }
}
</style>