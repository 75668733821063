import { createStore } from 'vuex'
import client from '@/mixins/client'

export default createStore({
    state: {
        params: null,
        token: null,
        authenticated: false,
        user: null,
    },
    actions: {
        setUser: async function ({commit}) {
            await client.methods.request('get', '/shops/me/').then(response => {
                commit('setUser', {user: response})
            })
        }
    },
    mutations: {
        setUser: (state, {user}) => {
            state.user = user
        },
    },
    getters: {}
})
