import {API_ROOT_URL, API_URL, PARAMS, APP} from '@/local'

let env = {
    api_root () {
        return API_ROOT_URL
    },
    api_url () {
        return API_URL
    },
    params () {
        let currentURL = (document.URL)

        let iframe = document.getElementsByName('app-iframe')[0]

        if (iframe !== undefined) {
            currentURL = iframe.contentWindow.location.href
        }

        let params = currentURL.split('?')[1]

        if (params === undefined) {
            if (currentURL.indexOf('app=' + APP) < 0) {
                params = 'app=' + APP
            }

            if (PARAMS['override']) {
                params += '&shop=' + PARAMS['shop_url']
                params += '&localhost=' + PARAMS['hash']
            } else {
                params += '&shop=' + window.location.hostname
            }
        } else {
            if (params.indexOf('app=' + APP) < 0) {
                params += '&app=' + APP
            }

            if (PARAMS['override']) {
                if (params.indexOf('shop=' + APP) < 0) {
                    params += '&shop=' + PARAMS['shop_url']
                }

                if (params.indexOf('localhost=' + APP) < 0) {
                    params += '&localhost=' + PARAMS['hash']
                }
            }
        }

        return params
    }
}

export default env
