<template>
    <div v-if="$store.state.token !== null">
        <Menu/>

        <main class="app-main">
            <ProgressBar :message="syncing.message"
                         :progress="syncing.progress"
                         v-if="syncing && syncing.active"/>
            <Notification :notification="notification"
                          @end="end_notification"
                          v-if="notification"/>
            <router-view :syncing="syncing ? syncing.active : false"
                         @notification="start_notification"
                         @syncing="start_syncing"/>
        </main>
    </div>

    <div v-else>
        Authenticating
    </div>

    <AbstractWelcome/>
</template>

<script>
import authentication from '@/mixins/authentication'
import Menu from '@/components/Menu'
import ProgressBar from '@/components/ProgressBar'
import Notification from '@/components/Notification'
import AbstractWelcome from '@/components/AbstractWelcome'

export default {
    name: 'App',
    mixins: [authentication],
    components: {
        ProgressBar,
        Notification,
        Menu,
        AbstractWelcome
    },
    data: function () {
        return {
            syncing: null,
            notification: null
        }
    },
    watch: {
        '$route': function () {
            this.end_notification()
        }
    },
    methods: {
        sync_data() {
            this.request('GET', '/shops/sync/').then(response => {
                if (response) {
                    this.start_notification({
                        message: 'All products from Shopify are now syncing.',
                        type: 'success'
                    })
                    this.start_syncing()
                } else {
                    this.start_notification({
                        message: 'Something went wrong.',
                        type: 'error'
                    })
                }
            })
        },
        start_notification(notification) {
            this.notification = notification

            this.resetNotification =  setTimeout(function () {
                this.end_notification()
            }.bind(this), 5000)
        },
        end_notification() {
            this.notification = null
            clearTimeout(this.resetNotification)
        },
        start_syncing() {
            this.checkSyncing = setInterval(() => {
                this.get_syncing()
            }, 1000)
        },
        get_syncing() {
            this.request('GET', '/shops/syncing/').then(response => {
                if (response && response.active) {
                    this.syncing = response
                } else {
                    this.syncing = null
                    clearInterval(this.checkSyncing)
                }
            })
        }
    },
    mounted () {
        this.$store.dispatch('setUser')

        window.fwSettings={
            'widget_id':72000000876
        };
        !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()

        const freshworksScript = document.createElement('script')
        freshworksScript.setAttribute('src', 'https://widget.freshworks.com/widgets/72000000876.js')
        freshworksScript.setAttribute('text', 'text/javascript')
        freshworksScript.setAttribute('async', 'async')
        freshworksScript.setAttribute('defer', 'defer')
        document.head.appendChild(freshworksScript)

        this.checkSyncing = setInterval(() => {
            this.get_syncing()
        }, 1000)
    }
}
</script>

<style lang="scss">
@import 'styles/variables';

.app-main {
    padding: 1rem 1rem 4rem 14rem;
}
</style>
