<template>
    <button class="button"
            :class="[size ? 'button--' + size : '', type ? 'button--' + type : '', fill ? 'fill': '', disabled ? 'disabled': '']">
        <i :class="icon" v-if="icon"/>

        <slot/>
    </button>
</template>

<script>
export default {
    name: 'CustomButton',
    props: [
        'icon',
        'type',
        'size',
        'fill',
        'disabled'
    ]
}
</script>

<style lang="scss" scoped>
@import '../styles/mixins';
@import '../styles/variables';

button,
.button {
    @include transition((background .1s, border-color .1s, color .1s, transform .1s));
    background: $white;
    border: 1px solid $medium-gray;
    border-radius: 2rem;
    color: $black;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    padding: calc(.6em - 1px) calc(1em - 1px);

    .svg-inline--fa {
        font-size: .75em;
        line-height: 1rem;
        margin-right: .5rem;
    }

    &:hover {
        background: $lighter-gray;
        transform: scale(1.05);
    }

    &.disabled {
        cursor: not-allowed;

        &:hover {
            background: $white;
            transform: none;
        }
    }

    &.fill {
        width: 100%;
    }

    &--primary {
        background: $primary-color;
        border-color: transparent;
        color: $white;

        &:hover {
            background: $primary-light-color;
        }

        &.disabled {
            &:hover {
                background: $primary-color;
            }
        }
    }

    &--secondary {
        background: $secondary-color;
        border-color: transparent;
        color: $white;

        &:hover {
            background: $secondary-light-color;
        }

        &.disabled {
            &:hover {
                background: $secondary-color;
            }
        }
    }

    &--success {
        background: $success-color;
        border-color: transparent;
        color: $white;

        &:hover {
            background: lighten($success-color, 5%);
        }

        &.disabled {
            &:hover {
                background: $success-color;
            }
        }
    }

    &--warning {
        background: $warning-color;
        border-color: transparent;
        color: $white;

        &:hover {
            background: lighten($warning-color, 5%);
        }

        &.disabled {
            &:hover {
                background: $warning-color;
            }
        }
    }

    &--alert {
        background: $alert-color;
        border-color: transparent;
        color: $white;

        &:hover {
            background: lighten($alert-color, 5%);
        }

        &.disabled {
            &:hover {
                background: $alert-color;
            }
        }
    }

    &--small {
        font-size: .85rem;
    }
}
</style>
