<template>
    <div class="progress-bar-area">
        <div class="progress-bar-area__inner">
            <div id="progress-bar-label" class="progress-bar-label" v-html="message"></div>

            <div class="progress-bar">
                <span id="progress-bar-progress" :style="'width: ' + progress + '%'"></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'message',
        'progress'
    ],
}
</script>

<style lang="scss">
@import '../styles/mixins';
@import '../styles/variables';

.progress-bar-area {
    height: 2rem;

    &__inner {
        left: 14rem;
        padding: 5px 0;
        position: absolute;
        right: 1rem;
        top: 4rem;
        z-index: 9;
    }
}

.progress-bar {
    background: $light-gray;
    border: 1px solid $medium-gray;
    border-radius: 5px;
    overflow: hidden;

    span {
        @include gradient($secondary-color, $primary-color, left);
        display: block;
        height: 8px;
        width: 0;
    }
}
</style>
