<template>
    <div class="actions cell shrink">
        <div class="grid-x">
            <div class="actions__action cell shrink" v-for="(action, i) in actions" :key="i">
                <CustomButton
                    :icon="action.icon"
                    :type="action.type"
                    @click="$emit('action', action.callback)">
                    {{ action.label }}
                </CustomButton>
            </div>
        </div>
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton'

export default {
    name: 'TagListHeader',
    props: [
        'actions'
    ],
    emits: [
        'sort',
        'action'
    ],
    components: {
        CustomButton
    }
}
</script>